<template>
    <div>
        <v-text-field
            v-if="searchable"
            v-model="searchTerm"
            outlined dense clearable
            label="Search"
            :placeholder="searchPlaceholder"
            hide-details
        />

        <v-treeview
            v-if="items.length"
            hoverable dense open-on-click
            :selectable="selectable"
            selection-type="independent"
            :items="items"
            :active="activeLoaded"
            multiple-active
            class="mx-n3 pointer"
            :value="selectedIds"
            :open.sync="openIds"
            :load-children="_loadChildren"
            @input="_input"
            ref="tree"
        >
            <template v-slot:label="{ item }">
                {{ item.name }}
                <span v-if="item.attrStr" class="grey--text">{{ item.attrStr }}</span>
            </template>
            <template v-slot:append="{ item }">
                <v-btn @click.stop="_viewInfo(item.id)" icon
                ><v-icon>{{ mdiInformationOutline }}</v-icon></v-btn>
            </template>
        </v-treeview>
        <div v-else class="text-center grey--text">{{ noData }}</div>

        <!-- Info dialog -->
        <v-dialog
            v-model="infoDialog"
            max-width="800"
            @click:outside="infoDialog = false"
        >
            <v-card @copy.native="_XPathCopy">
                <v-card-title class="headline text-center">Variable Info: {{ infoData.name }}</v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col class="col-2">XPath</v-col><v-col>{{ (infoData.xpath || '') | xpath }}</v-col>
                    </v-row>

                    <v-row dense v-for="(value, key) in (infoData.attr || {})" :key="key" v-if="key !== 'mapType'">
                        <v-col class="col-2">@{{ key }}</v-col><v-col>{{ value }}</v-col>
                    </v-row>

                    <v-row dense v-if="showToolOwners">
                        <v-col class="col-2">Tools</v-col><v-col>{{ infoTools }}</v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="infoDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import {api} from "@/main";
    import {store} from '@/store';
    import {mdiInformationOutline} from '@mdi/js';
    import {getDataAncestors, getAllDataAncestors, filterTrees} from "@/model/ops-utils";

    import map from "lodash/map";
    import sortBy from "lodash/sortBy";
    import filter from "lodash/filter";
    import assign from "lodash/assign";
    import forEach from "lodash/forEach";
    import isEqual from "lodash/isEqual";
    import includes from "lodash/includes";
    import debounce from "lodash/debounce";
    import cloneDeep from "lodash/cloneDeep";
    import difference from "lodash/difference";

    // Insert zero-width-spaces when displaying xpaths: https://stackoverflow.com/a/24489931
    Vue.filter('xpath', (xpath) => xpath.replace(/\//g, '\u200B/'));

    export default Vue.extend({
        name: "data-tree",
        props: {
            noDataText: {default: null},
            data: {default: () => []},
            showToolOwners: {default: true},
            editable: {default: true},
            selectable: {type: Boolean, default: false},
            value: {default: null},
            searchable: {default: false},
            searchToolId: {default: null},
            searchIsInput: {default: true},
        },
        data: () => ({
            mdiInformationOutline,

            infoDialog: false,
            infoData: {},

            selectedIds: [],
            setSelectedId: [],
            ignoreInput: false,
            openIds: [],

            searchTerm: '',
            searchedTerm: '',
            searchIds: [],
            loadedTrigger: 1,
            loadedIds: {},
        }),
        watch: {
            value(value) {
                this._setValue(value);
                this._resetIndeterminate();
            },
            data: {
                handler() {
                    // Workaround for bug related to buildTree
                    // https://github.com/vuetifyjs/vuetify/issues/8791#issuecomment-831648970

                    const value = this.value;
                    this.ignoreInput = true;
                    this.$nextTick(() => {
                        this._setValue(value);
                        this._resetIndeterminate();
                    });
                },
                deep: true,
            },
            searchTerm(value) {
                if (!value) {
                    this._search(value);
                } else {
                    this.debouncedSearch(value);
                }
            },
        },
        computed: {
            project: () => store.state.localProject,
            noData() {
                if (this.hasSearch) return 'No search results';
                return (this.noDataText) ? this.noDataText: 'No data';
            },
            items() {
                // https://github.com/vuejs/vue/issues/2410#issuecomment-318487855
                if (!this.loadedTrigger) return [];
                const loadedIds = this.loadedIds;

                function getItems(dataList, topLevel) {
                    return map(dataList, (data) => {
                        const attrs = [];
                        forEach(data.attr, (value, key) => {
                            if (key === 'mapType' || value.indexOf('.xsd') !== -1) return;

                            let keyStr = key;
                            if (keyStr.length > 13) keyStr = keyStr.substr(0, 10)+'...';
                            attrs.push('@'+keyStr+': '+value);
                        });
                        const attrStr = (attrs.length > 0) ? '('+attrs.join(', ')+')': null;

                        const hasChildren = data.children.length > 0;
                        const children = (hasChildren) ? getItems(data.children): undefined;
                        const childrenLoaded = topLevel || (data.id in loadedIds);

                        return assign({}, data, {
                            attrStr,
                            children: (childrenLoaded) ? children: (hasChildren) ? []: undefined,
                            itemChildren: children,
                        })
                    });
                }
                return getItems(this.filteredData, true);
            },
            itemMap() {
                const map = {};

                function processData(dataList) {
                    for (const data of dataList) {
                        map[data.id] = data;
                        processData(data.children);
                    }
                }
                processData(this.data);
                return map;
            },
            infoTools() {
                const toolIds = this.infoData.toolIds || [];
                return sortBy(map(filter(this.project.tools,
                    (tool) => includes(toolIds, tool.id)), (tool) => tool.name)).join(', ');
            },
            selectedActiveIds() {
                if (!this.selectable || !this.value) return [];
                return getDataAncestors(this.data, this.value);
            },
            hasSearch() {
                return this.searchable && this.searchedTerm;
            },
            active() {
                if (this.infoDialog) return [this.infoData.id];
                if (this.hasSearch) return this.searchIds;
                return this.selectedActiveIds;
            },
            activeLoaded() {
                if (!this.loadedTrigger) return []; // To trigger reload
                const loadedIds = this.loadedIds;
                return filter(this.active, (id) => id in loadedIds);
            },
            searchPlaceholder() {
                return (this.selectable) ? 'Search: xpath or !selected': 'Search: xpath';
            },
            debouncedSearch() {
                return debounce((term) => this._search(term), 500);
            },
            filteredData() {
                if (!this.hasSearch) return this.data;
                return filterTrees(this.data, this.searchIds);
            },
        },
        methods: {
            _viewInfo(id) {
                const itemMap = this.itemMap;
                if (!(id in itemMap)) return;

                this.infoData = itemMap[id];
                this.infoDialog = true;
            },
            _setValue(value) {
                if (!this.selectable) return;
                this.searchTerm = '';
                this.ignoreInput = true;

                this._addLoadedIds(this.active);
                if (value && (value in this.itemMap)) {
                    this.setSelectedId = [value];
                    this.selectedIds = [value];
                } else {
                    this.setSelectedId = [];
                    this.selectedIds = [];
                }
                this.$nextTick(() => {
                    this.ignoreInput = false;
                });
            },
            _input(selectedId) {
                if (!this.selectable || this.ignoreInput) return;

                if (selectedId.length > 0) {
                    const id = difference(selectedId, this.setSelectedId)[0];
                    this.$emit('input', id);
                    this.selectedIds = [id];
                } else {
                    this.$emit('input', null);
                }
            },
            _search(term) {
                this.searchedTerm = term;
                if (!term) {
                    this.searchIds = [];
                } else if (this.selectable && term === '!selected') {
                    this.searchIds = cloneDeep(this.selectedIds);
                } else {
                    api.searchData(this.searchToolId, this.searchIsInput, term, (searchIds) => {
                        this.searchIds = searchIds;

                        const ancestorIds = getAllDataAncestors(this.data, searchIds, false);
                        this._addLoadedIds(ancestorIds);
                        this.openIds = ancestorIds;
                    });
                }
            },
            expandValue() {
                this.$nextTick(() => {
                    const ancestorIds = getDataAncestors(this.data, this.value);
                    this._addLoadedIds(ancestorIds);
                    this.openIds = ancestorIds;
                });
            },
            _addLoadedIds(loadedIds) {
                if (!loadedIds.length) return;
                const currentLoadedIds = cloneDeep(this.loadedIds);
                forEach(loadedIds, (id) => this.loadedIds[id] = true);

                if (!isEqual(currentLoadedIds, this.loadedIds)) {
                    this.loadedTrigger += 1; // Trigger recompute
                    this.$nextTick(() => {
                        this._resetIndeterminate();
                    });
                }
            },
            _loadChildren(item) {
                this.$set(this.loadedIds, item.id, true);
            },

            _XPathCopy(e) {
                // https://developer.mozilla.org/en-US/docs/Web/API/Element/copy_event
                const xpathSelected = document.getSelection().toString();
                const xpathCopy = xpathSelected.replace(/\u200B/g, '');
                e.clipboardData.setData('text/plain', xpathCopy);
                e.preventDefault();
            },
            _resetIndeterminate() {
                // Workaround for bug related to buildTree
                // https://github.com/vuetifyjs/vuetify/issues/8791#issuecomment-831648970
                if (!this.$refs.tree) return;
                forEach(this.$refs.tree.nodes, (node) => {
                    if (node.isIndeterminate) {
                        node.isIndeterminate = false;
                        if (node.vnode) {
                            node.vnode.isIndeterminate = false;
                        }
                    }
                });
            },
        },
        mounted() {
            this._setValue(this.value);
        },
    });
</script>

<style scoped>

</style>