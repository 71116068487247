import * as m from './model';
import {MultiActionType, NoneActionType} from './model';

import map from 'lodash/map';
import uniq from 'lodash/uniq';
import clone from 'lodash/clone';
import concat from 'lodash/concat';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';


export function getDefaultOps(isQoi: boolean, isOutput: boolean): m.Operation {
    return (isQoi) ? {
        type: (isOutput) ? m.OperationType.READ: m.OperationType.WRITE,
        dataXpath: null, dataId: null,
        // Sync default values with Python model!
        noneAction: NoneActionType.DO_NOTHING,
        multiAction: MultiActionType.VALUE_LIST,
    }: {
        type: m.OperationType.WRITE_NR_INST,
        dataXpath: null, dataId: null,
        noneAction: NoneActionType.DO_NOTHING,
        multiAction: MultiActionType.VALUE_LIST,
    };
}

export function getDataAncestors(dataList: m.Data[], id: m.idType, includeSelf=true): m.idType[] {

    function getAncestors(subDataList: m.Data[], parents: m.Data[]): m.idType[]|undefined {
        for (const data of subDataList) {
            if (data.id === id) {
                const ancestorIds = map(parents, (parent) => parent.id);
                return (includeSelf) ? concat(ancestorIds, data.id): ancestorIds;
            }

            if (data.children.length > 0) {
                const ancestors = getAncestors(data.children, concat(parents, data));
                if (ancestors !== undefined) return ancestors;
            }
        }
    }

    return getAncestors(dataList, []) || [];
}

export function getAllDataAncestors(dataList: m.Data[], ids: m.idType[], includeSelf=true): m.idType[] {
    return uniq(flatten(map(ids, (id) => getDataAncestors(dataList, id, includeSelf))));
}

export function filterTrees(dataList: m.Data[], ids: m.idType[], ancestorIds?: { [key: number]: number }): m.Data[] {
    if (!ancestorIds) ancestorIds = fromPairs(map(getAllDataAncestors(dataList, ids), (id) => [id, id]));
    const idsMap = fromPairs(map(ids, (id) => [id, id]));

    const filtered: m.Data[] = [];
    for (const data of dataList) {
        if (data.id in ancestorIds) {

            let children: m.Data[] = [];
            if (data.id in idsMap) {
                children = data.children;
            } else {
                children = filterTrees(data.children, ids, ancestorIds);
            }

            const filteredData: m.Data = clone(data);
            filteredData.children = children;
            filtered.push(filteredData);
        }
    }
    return filtered;
}
