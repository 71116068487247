import * as m from '@/model/model';
import {getNextId, editProject} from "@/store";

import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';

export function addComponent(name: string): m.Component {
    const comp = {
        id: getNextId(),
        name,
        qois: [],
    };

    editProject((project) => {
        project.components.push(comp);
    });
    return comp;
}
export function editComponent(id: m.idType, edit: (comp: m.Component) => void, callback?: (comp: m.Component) => void) {
    editProject((project) => {
        forEach(project.components, (comp) => {
            if (comp.id === id) edit(comp);
        });
    }, undefined, true, true, (project) => {
        if (!callback) return;
        forEach(project.components, (comp) => {
            if (comp.id === id) callback(comp);
        });
    });
}
export function deleteComponent(id: m.idType) {
    editProject((project) => {
        project.components = filter(project.components, (comp) => comp.id !== id);
    });
}

export function addQOI(name: string): m.QOI {
    const qoi = {
        id: getNextId(),
        name,
        isOutput: true,
    };

    editProject((project) => {
        project.qois.push(qoi);
    });
    return qoi;
}
export function editQOI(id: m.idType, edit: (qoi: m.QOI) => void, callback?: (qoi: m.QOI) => void) {
    editProject((project) => {
        forEach(project.qois, (qoi) => {
            if (qoi.id === id) edit(qoi);
        });
    }, undefined, true, true, (project) => {
        if (!callback) return;
        forEach(project.qois, (qoi) => {
            if (qoi.id === id) callback(qoi);
        });
    });
}
export function deleteQOI(id: m.idType) {
    editProject((project) => {
        project.qois = filter(project.qois, (qoi) => qoi.id !== id);

        forEach(project.components, (comp) => {
            comp.qois = filter(comp.qois, (qoiId) => qoiId !== id);
        });
    });
}
export function assignQOI(id: m.idType, compIds: m.idType[]) {
    editProject((project) => {
        forEach(project.components, (comp) => {
            if (includes(compIds, comp.id)) {
                if (!includes(comp.qois, id)) comp.qois.push(id);
            } else {
                comp.qois = filter(comp.qois, (qoiId) => qoiId !== id);
            }
        });
    });
}

export function addTool(name: string): m.Tool {
    const tool = {
        id: getNextId(),
        name,
        input: [], output: [],
        selected: true,
    };

    editProject((project) => {
        project.tools.push(tool);
    });
    return tool;
}
export function editTool(id: m.idType, edit: (tool: m.Tool) => void) {
    editProject((project) => {
        forEach(project.tools, (tool) => {
            if (tool.id === id) edit(tool);
        });
    });
}
export function deleteTool(id: m.idType) {
    editProject((project) => {
        project.tools = filter(project.tools, (tool) => tool.id !== id);
    });
}

export function getName(name: string, existing: string[]): string {
    if (!includes(existing, name)) return name;

    let i = 2;
    const base = name;
    while (includes(existing, name)) {
        name = base+' '+i.toString();
        i++;
    }
    return name;
}
