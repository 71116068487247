import {api} from "@/main";
import {elementToSVG, inlineResources} from "dom-to-svg";
import {saveFile, saveBlob, saveFileContentCallback} from "@/files";
import * as htmlToImage from 'html-to-image';

export enum ExportTypes {
    // MLQ = "MLQ",
    SVG = "SVG",
    PDF = "PDF",
    PNG = "PNG",
}

export const exportTypeTitles = {
    // [ExportTypes.MLQ]: '*.mlq Project',
    [ExportTypes.SVG]: 'SVG',
    [ExportTypes.PDF]: 'PDF',
    [ExportTypes.PNG]: 'PNG',
};

export function exportMatrix(matrixElement: HTMLElement, type: ExportTypes, name: string, toolHeaderRotation: number,
                             callback?: () => void) {
    // if (type == ExportTypes.MLQ) {
    //     api.downloadProject(null, (fileContent) => {
    //         saveFileContentCallback(fileContent);
    //         if (callback) callback();
    //     });
    //
    // } else
    if (type == ExportTypes.SVG) {
        getExportSvg(matrixElement, toolHeaderRotation, (svg) => {
            const svgData = (new XMLSerializer()).serializeToString(svg);
            saveFile(name+'.svg', svgData, 'image/svg+xml');
            if (callback) callback();
        });

    } else if (type == ExportTypes.PDF) {
        getExportSvg(matrixElement, toolHeaderRotation, (svg) => {
            const svgData = (new XMLSerializer()).serializeToString(svg);
            api.renderSvg(svgData, 'pdf', (fileContent) => {
                fileContent.filename = name+'.pdf';
                saveFileContentCallback(fileContent);
                if (callback) callback();
            });
        });

    } else if (type == ExportTypes.PNG) {
        htmlToImage.toBlob(matrixElement).then((blob) => {
            if (!blob) throw new Error('Could not get blob from canvas');
            saveBlob(name+'.png', blob);
            if (callback) callback();
        });

    } else {
        if (callback) callback();
    }
}

function getExportSvg(matrixElement: HTMLElement, toolHeaderRotation: number, callback: (svg: XMLDocument) => void) {

    const svg = elementToSVG(matrixElement);

    // Fix tool name rotations
    const header = svg.getElementById('header1');
    if (header) {
        for (const toolName of header.getElementsByClassName('tool-name')) {
            const textEl = toolName.getElementsByTagName('text')[0];
            const tSpanEl = textEl.children[0];

            const x = parseFloat(tSpanEl.getAttribute('x') || '0');
            const dx = 18;
            const y = parseFloat(tSpanEl.getAttribute('y') || '0');
            const rotateParams = [toolHeaderRotation.toString(), x.toString(), y.toString()];
            textEl.setAttribute('transform', 'translate('+dx.toString()+') rotate('+rotateParams.join(' ')+')');

            const tspanEl = textEl.getElementsByTagName('tspan')[0];
            tspanEl.removeAttribute('textLength');
        }
    }

    // Remove style element if empty
    for (const child of svg.documentElement.children) {
        if (child.tagName == 'style' && !child.innerHTML) {
            child.parentElement?.removeChild(child);
        }
    }

    inlineResources(svg.documentElement).then(() => {
        callback(svg);
    });
}
