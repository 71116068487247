<template>
    <div class="fill-height">
        <!-- No mappings yet --->
        <div class="d-flex align-center justify-center fill-height" v-if="!verifyAvailable">
            <div class="flex flex-grow-0 text-center">
                <h2 class="font-weight-light mb-4">
                    <span>No Quanties of Interest defined yet...</span>
                </h2>
            </div>
        </div>

        <!-- Verification interface -->
        <div class="fill-height d-flex align-stretch flex-column" v-else>
            <!-- Files select -->
            <div class="d-flex align-center align-stretch flex-grow-0">
                <v-container style="max-width: 1200px">
                    <v-row class="mb-n8">
                        <v-col cols="12" md="4">
                            <v-select
                                :disabled="!hasArchTypeSelect"
                                v-model="archType"
                                :items="archTypes"
                                label="Architecture data type"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <div v-if="hasArchFile" style="display: inline-block" class="mr-2">
                                <span class="text-caption">Architecture database:</span><br />
                                {{ amState.archDataFile }}
                            </div>
                            <v-btn :color="(hasArchFile) ? null: 'primary'" @click="_setArchData"
                            >{{ (hasArchFile) ? 'Select': 'Select Architecture Database' }}</v-btn>
                        </v-col>
                        <v-col cols="12" md="4" v-if="hasArchFile">
                            <v-select
                                v-if="hasArchSelect"
                                v-model="editArchKey"
                                :items="archSelectItems"
                                label="Architecture"
                                persistent-placeholder
                                placeholder="Select architecture..."
                                @input="_selectArchitecture"
                            />
                            <v-alert v-else type="error">
                                No architectures in database!
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <div v-if="hasInitFile" style="display: inline-block" class="mr-2">
                                <span class="text-caption">Initial product data:</span><br />
                                {{ amState.initDataFile }}
                            </div>
                            <v-btn :color="(hasInitFile) ? null: 'primary'" @click="_setInitData"
                            >{{ (hasInitFile) ? 'Select': 'Select Initial Product Data' }}</v-btn>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select
                                :disabled="!hasDataTypeSelect"
                                v-model="dataType"
                                :items="dataTypes"
                                label="Product data type"
                            />
                        </v-col>
                        <v-col cols="12" md="4">
                            <div v-if="hasOutputFile" style="display: inline-block" class="mr-2">
                                <span class="text-caption">Output product data:</span><br />
                                {{ amState.outputDataFile }}
                            </div>
                            <v-btn @click="_setOutputData"
                            >{{ (hasOutputFile) ? 'Select': 'Select Output Product Data' }}</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <!-- Inspecting I/O mapping -->
            <div class="flex-grow-1 d-flex align-stretch" style="position: relative;" v-if="hasArchSelection">
                <div class="content-sub-wrapper">
                    <div class="fill-height d-flex align-stretch">
                        <!-- Input -->
                        <div class="col-6 pa-0 scroll">
                            <v-container fluid v-if="hasInitFile">
                                <h2 class="text-center mb-2">Input Mapping</h2>
                                <div class="text-center mb-6">
                                    <v-btn :disabled="isMapping" color="success" @click="_doMapping">
                                        <v-progress-circular v-if="isMapping" indeterminate size="12" width="2" class="ml-n1 mr-2" />
                                        <v-icon left v-else>{{ mdiRefresh }}</v-icon>
                                        Update Mapping
                                    </v-btn>

                                    <v-btn :disabled="isMapping" color="primary" @click="_downloadMapped" class="ml-4"
                                    ><v-icon left>{{ mdiDownload }}</v-icon> Download Mapped File</v-btn>
                                </div>

                                <verify-arch-data
                                    v-if="archInputData !== null"
                                    :arch-inst-data="archInputData"
                                />
                            </v-container>
                        </div>

                        <!-- Output -->
                        <div class="col-6 pa-0 scroll">
                            <v-container fluid v-if="hasOutputFile">
                                <h2 class="text-center mb-2">Output Mapping</h2>
                                <div class="text-center mb-6">
                                    <v-btn :disabled="isMapping" color="success" @click="_doMapping">
                                        <v-progress-circular v-if="isMapping" indeterminate size="12" width="2" class="ml-n1 mr-2" />
                                        <v-icon left v-else>{{ mdiRefresh }}</v-icon>
                                        Update Mapping
                                    </v-btn>
                                </div>

                                <verify-arch-data
                                    v-if="archOutputData !== null"
                                    :arch-inst-data="archOutputData"
                                />
                            </v-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store, updatedArchMappingState} from '@/store';
    import {api, dispatcher} from "@/main";
    import {openFile, saveFileContentCallback} from "@/files";
    import {mdiRefresh, mdiDownload} from '@mdi/js';

    import map from 'lodash/map';

    import verifyArchData from './verify-arch-data.vue';

    export default Vue.extend({
        name: "verify",
        components: {
            verifyArchData,
        },
        data: () => ({
            mdiRefresh, mdiDownload,

            archTypes: [
                { value: 'adore', text: 'ADORE Architectures' },
            ],
            archType: 'adore',

            dataTypes: [
                { value: 'xml', text: 'XML' },
            ],
            dataType: 'xml',

            editArchKey: null,
            isMapping: false,
        }),
        watch: {
            archKey(archKey) {
                this.editArchKey = archKey;
            },
        },
        computed: {
            project: () => store.state.localProject,
            hasQOIs() {
                return this.project.qois.length > 0;
            },
            verifyAvailable() {
                return this.hasQOIs;
            },

            amState: () => store.state.archMappingState,
            hasInitFile() {
                return (this.amState) ? !!this.amState.initDataFile: false;
            },
            hasOutputFile() {
                return (this.amState) ? !!this.amState.outputDataFile: false;
            },
            hasArchFile() {
                return (this.amState) ? !!this.amState.archDataFile: false;
            },
            architectures() {
                return (this.amState) ? this.amState.architectures: [];
            },
            hasArchSelect() {
                return this.architectures.length > 0;
            },
            archSelectItems() {
                return map(this.architectures, ([key, name]) => ({ value: key, text: name }));
            },
            archKey() {
                return (this.amState) ? this.amState.archKey: null;
            },
            hasArchSelection() {
                return !!this.archKey;
            },

            archInputData() {
                return (this.amState) ? this.amState.archInputData: null;
            },
            archOutputData() {
                return (this.amState) ? this.amState.archOutputData: null;
            },

            hasArchTypeSelect() {
                return this.archTypes.length > 1;
            },
            hasDataTypeSelect() {
                return this.dataTypes.length > 1;
            },
        },
        methods: {
            _setArchData() {
                if (!this.archType) return;
                openFile((data, name) => {
                    api.archMappingUploadArch(this.archType, data, name, updatedArchMappingState);
                });
            },
            _setInitData() {
                if (!this.dataType) return;
                openFile((data, name) => {
                    api.archMappingUploadInit(this.dataType, data, name, (state) => {
                        updatedArchMappingState(state);
                        this._doMapping();
                    });
                });
            },
            _setOutputData() {
                if (!this.dataType) return;
                openFile((data, name) => {
                    api.archMappingUploadOutput(this.dataType, data, name, (state) => {
                        updatedArchMappingState(state);
                        this._doMapping();
                    });
                });
            },
            _selectArchitecture() {
                if (!this.editArchKey) return;
                api.archMappingSelectArch(this.editArchKey, updatedArchMappingState);
            },
            _doMapping() {
                if (!this.hasArchSelection || this.isMapping) return;
                this.isMapping = true;
                let inDelay = true;
                let finished = false;
                // At show that mapping is being done for a little while to give the feeling of actually something happening
                setTimeout(() => {
                    inDelay = false;
                    if (finished) this.isMapping = false;
                }, 300);
                api.archMappingDoMapping((state) => {
                    finished = true;
                    if (!inDelay) this.isMapping = false;
                    updatedArchMappingState(state);
                });
            },
            _downloadMapped() {
                api.archMappingDownloadMappedInput(saveFileContentCallback);
            },

            _onFileOps() {
                api.archMappingReset(updatedArchMappingState);
            },
        },
        created() {
            api.archMappingGetState(updatedArchMappingState);
            dispatcher.onFileOps(this._onFileOps);
        },
    });
</script>

<style scoped>
    .content-sub-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
</style>