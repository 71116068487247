<template>
    <div class="px-10">
        <div v-if="compItems.length > 0">
            <h3 class="text-center">Component Instances</h3>
            <v-data-table
                :headers="compHeaders"
                :items="compItems"
                dense disable-pagination hide-default-footer
            />
        </div>

        <div v-if="qoiItems.length > 0">
            <h3 class="text-center">QOI Instances</h3>
            <v-data-table
                :headers="qoiHeaders"
                :items="qoiItems"
                dense disable-pagination hide-default-footer
            />
        </div>
    </div>
</template>

<script>
    import Vue from "vue";

    import map from 'lodash/map';
    import some from 'lodash/some';
    import filter from 'lodash/filter';

    export default Vue.extend({
        name: "verify-arch-data",
        props: {
            archInstData: {required: true},
        },
        data: () => ({
            compHeaders: [
                // { text: '#', value: 'idx' },
                { text: 'Component', value: 'name' },
                { text: 'Instances', value: 'nr' },
            ],
            qoiHeadersBase: [
                { text: 'Component', value: 'comp' },
                { text: 'QOI', value: 'name' },
                { text: 'Instance #', value: 'idx' },
                { text: 'Value', value: 'value' },
            ],
        }),
        computed: {
            compItems() {
                /** @type ComponentInstance[] */
                const compInstList = this.archInstData.components;
                const compDict = {};
                const components = [];
                for (const compInst of compInstList) {
                    if (!(compInst.component.id in compDict)) {
                        compDict[compInst.component.id] = components.length;
                        components.push([compInst.component, 0]);
                    }
                    components[compDict[compInst.component.id]][1]++;
                }

                return map(components, ([component, nr], idx) => {
                    return {
                        idx: idx+1,
                        name: component.name,
                        nr,
                    };
                });
            },
            qoiItems() {
                const qoiItems = [];
                const qoiCounter = {};
                /**
                 * @param {QOIInstance[]} qoiInstList
                 * @param {string|null} owningCompName
                 * @param {number|null} owningCompIdx
                 */
                function processQOIInstances(qoiInstList, owningCompName, owningCompIdx) {
                    for (const qoiInst of qoiInstList) {
                        if (!(qoiInst.qoi.id in qoiCounter)) qoiCounter[qoiInst.qoi.id] = 0;

                        const comp = (owningCompName !== null) ? `${owningCompName} #${owningCompIdx+1}`: '';

                        let value = 'Empty';
                        if (qoiInst.value !== null && qoiInst.value !== undefined) {
                            if (typeof qoiInst.value === 'number') {
                                if (isNaN(qoiInst.value)) {
                                    value = 'NaN'
                                } else {
                                    value = qoiInst.value.toString();
                                }
                            } else {
                                value = qoiInst.value;
                            }
                        }

                        qoiItems.push({
                            name: qoiInst.qoi.name,
                            idx: qoiCounter[qoiInst.qoi.id]+1,
                            comp,
                            value,
                        });
                        qoiCounter[qoiInst.qoi.id]++;
                    }
                }

                /** @type ComponentInstance[] */
                const compInstList = this.archInstData.components;
                const compCounter = {};
                for (const compInst of compInstList) {
                    if (!(compInst.component.id in compCounter)) compCounter[compInst.component.id] = 0;
                    processQOIInstances(compInst.qoiInstances, compInst.component.name, compCounter[compInst.component.id]);
                    compCounter[compInst.component.id]++;
                }
                processQOIInstances(this.archInstData.qois, null, null);
                return qoiItems;
            },
            hasQoiColData() {
                return some(this.qoiItems, (item) => !!item.comp);
            },
            qoiHeaders() {
                if (this.hasQoiColData) return this.qoiHeadersBase;
                return filter(this.qoiHeadersBase, (col) => col.value !== 'comp');
            },
        },
    });
</script>

<style scoped>

</style>