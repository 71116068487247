<template>
    <div class="fill-height">
        <div class="fill-height d-flex align-stretch">
            <!-- Left pane -->
            <div class="col-3 pa-0 scroll">
                <v-container fluid>
                    <!-- Add buttons -->
                    <div class="d-flex justify-center mb-3" v-if="editable">
                        <div>
                            <v-btn
                                v-if="canAddRemove"
                                :color="(canImport) ? null: 'primary'"
                                @click="_addTool"
                            ><v-icon left>{{ mdiPlus }}</v-icon>Tool</v-btn>

                            <v-btn
                                v-if="canImport"
                                color="primary"
                                :class="{'ml-2': canAddRemove}"
                                @click="_import"
                            ><v-icon left>{{ mdiUpload }}</v-icon>Import</v-btn>
                        </div>
                    </div>

                    <v-treeview
                        :active.sync="selected"
                        activatable hoverable open-all
                        :items="items"
                        class="mx-n3 pointer"
                        ref="tree"
                    >
                        <template v-slot:prepend="{ item }">
                            <v-icon>{{ mdiCalculator }}</v-icon>
                        </template>
                        <template v-slot:append="{ item }">
                            <v-btn @click.stop="_deleteTool(item.id)" icon v-if="canAddRemove"
                            ><v-icon>{{ mdiTrashCan }}</v-icon></v-btn>
                        </template>
                    </v-treeview>
                </v-container>
            </div>

            <v-divider vertical v-if="hasSelection" />

            <!-- Edit screen -->
            <div class="col-3 pa-0 scroll">
                <v-container fluid v-if="hasSelection">
                    <h1>Editing Tool</h1>

                    <v-form v-model="formValid" ref="form" @submit.prevent="">
                        <!-- Name field -->
                        <v-text-field
                            v-model="editedItem.name"
                            :disabled="!canEditProps"
                            label="Name"
                            @blur="_updateItem"
                            @keyup.enter.stop="_updateItem"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!-- Ref field -->
                        <v-text-field
                            v-model="editedItem.ref"
                            :disabled="!canEditRef"
                            label="External reference"
                            hide-details="auto"
                            @blur="_updateItem"
                            @keyup.enter.prevent="_updateItem"
                        ></v-text-field>
                    </v-form>

                    <!-- The purpose of this button is to make the user deselect the field to trigger an update -->
                    <div class="text-center mt-6" v-if="canEditProps">
                        <v-btn color="primary" :disabled="!formValid">Update</v-btn>
                    </div>
                </v-container>
            </div>

            <v-divider vertical v-if="hasSelection" />

            <!-- Edit I/O screen -->
            <div class="col pa-0 scroll">
                <v-container fluid v-if="hasSelection">
                    <v-row align="start">
                        <!-- Input -->
                        <v-col class="col-12 col-lg-6">
                            <h2 class="text-center mb-2">Inputs</h2>

                            <!-- Import button -->
                            <div class="d-flex justify-center mb-3" v-if="canImportIO">
                                <div>
                                    <v-btn color="accent" @click="_importIO(true)"
                                    ><v-icon left>{{ mdiUpload }}</v-icon>Select Input File</v-btn>

                                    <v-btn
                                        v-if="selectedItem.input.length"
                                        color="error" class="ml-2"
                                        @click="_clearIO(true)"
                                    ><v-icon left>{{ mdiDelete }}</v-icon>Clear Input</v-btn>
                                </div>
                            </div>

                            <data-tree
                                v-if="hasSelection"
                                :data="selectedItem.input"
                                no-data-text="No input data"
                                :show-tool-owners="false"
                                :searchable="true" :search-tool-id="selectedId"
                            />
                        </v-col>

                        <!-- Output -->
                        <v-col class="col-12 col-lg-6">
                            <h2 class="text-center mb-2">Outputs</h2>

                            <!-- Import button -->
                            <div class="d-flex justify-center mb-3" v-if="canImportIO">
                                <div>
                                    <v-btn color="accent" @click="_importIO(false)"
                                    ><v-icon left>{{ mdiUpload }}</v-icon>Select Output File</v-btn>

                                    <v-btn
                                        v-if="selectedItem.output.length"
                                        color="error" class="ml-2"
                                        @click="_clearIO(false)"
                                    ><v-icon left>{{ mdiDelete }}</v-icon>Clear Output</v-btn>
                                </div>
                            </div>

                            <data-tree
                                v-if="hasSelection"
                                :data="selectedItem.output"
                                no-data-text="No output data"
                                :show-tool-owners="false"
                                :searchable="true" :search-tool-id="selectedId" :search-is-input="false"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>

        <!-- Tools import dialog -->
        <v-dialog
            v-model="importDialog"
            max-width="800"
            @click:outside="importDialog = false"
        >
            <v-card :loading="importPending || importSetsLoading">
                <v-card-title class="headline text-center">Import Tools</v-card-title>

                <!-- Import from a list of available sets -->
                <v-card-text v-if="importBackendSets">
                    <v-radio-group v-if="importSets.length > 0" v-model="importSetIdx">
                        <v-radio v-for="(title, idx) in importSets" :key="idx" :value="idx">
                            <template v-slot:label>
                                <div>{{ title }}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-else class="text-center grey--text">Nothing to import...</div>
                </v-card-text>

                <!-- Import from file -->
                <v-card-text v-else>
                    <v-radio-group v-model="importKey">
                        <v-radio v-for="(value, key) in importKeys" :key="key" :value="key">
                            <template v-slot:label>
                                <div>
                                    <span style="font-weight: bold">{{ value[0] }}: </span>
                                    <span style="font-size: small">{{ value[1] }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="importDialog = false">Close</v-btn>

                    <v-btn v-if="importBackendSets" text color="primary" @click="_importSet"
                           :disabled="importSetIdx === null || importSets.length === 0">Import</v-btn>
                    <v-btn v-else text color="primary" @click="_importSelectFile"
                           :disabled="!importKey">Select File</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store, updatedProject} from '@/store';
    import {api, dispatcher} from "@/main";
    import {openFile} from "@/files";
    import {getName, addTool, editTool, deleteTool} from "@/model/model-ops";

    import {mdiPlus, mdiTrashCan, mdiUpload, mdiCalculator, mdiDelete} from '@mdi/js';

    import map from 'lodash/map';
    import find from 'lodash/find';
    import sortBy from 'lodash/sortBy';
    import cloneDeep from 'lodash/cloneDeep';

    import dataTree from './data-tree.vue'

    export default Vue.extend({
        name: "tools",
        components: {
            dataTree,
        },
        data: () => ({
            mdiPlus, mdiTrashCan, mdiUpload, mdiCalculator, mdiDelete,

            formValid: false,
            editedItem: {},

            selected: [],
            toolInputActive: [],
            toolOutputActive: [],

            importDialog: false,
            importKeys: {
                zip_xml: ['XML database (.zip)', 'a .zip file with I/O files in XML format (*-input.xml and *-output.xml for each tool).'],
                cmdows: ['CMDOWS (.xml)', 'a CMDOWS xml file with tools defined in it.'],
            },
            importKey: 'zip_xml',
            importPending: false,
            importSets: [],
            importSetIdx: null,
            importSetsLoading: false,

            rules: {
                required: (value) => !!value || 'Required',
            },
        }),
        watch: {
            hasSelection() {
                this._changeSelection();
            },
            selectedId() {
                this._changeSelection();
            },
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            hasBackendUI: () => store.state.settings.has_backend_ui,
            canAddRemove() {
                return this.editable && store.state.settings.edit_tools;
            },
            canEditProps() {
                return this.canAddRemove;
            },
            canImport() {
                return this.editable && store.state.settings.import_tools;
            },
            importBackendSets() {
                return store.state.settings.db_provides_tools;
            },
            canImportIO() {
                return this.canEditProps;
            },
            canEditRef() {
                return this.editable && store.state.settings.edit_refs;
            },
            tools() {
                return (this.project) ? this.project.tools: [];
            },
            items() {
                return sortBy(map(this.tools, (tool) => ({
                    id: tool.id,
                    name: tool.name,
                })), 'name');
            },

            selectedId() {
                return (this.selected.length > 0) ? this.selected[0]: null;
            },
            hasSelection() {
                return this.selectedId !== null;
            },
            selectedItem() {
                if (!this.hasSelection) return {};
                const selectedId = this.selectedId;
                return find(this.tools, (tool) => tool.id === selectedId) || {};
            },
        },
        methods: {
            _addTool() {
                const toolNames = map(this.tools, (tool) => tool.name);
                const tool = addTool(getName('New tool', toolNames));
                this._select(tool.id);
            },
            _deleteTool(id) {
                deleteTool(id);
                if (this.selectedId === id) this._select(null);
            },
            _import() {
                if (!this.canImport) return;
                this.importPending = false;
                this.importDialog = true;

                if (this.importBackendSets) {
                    this.importSets = [];
                    this.importSetIdx = null;
                    this.importSetsLoading = true;
                    api.getToolSets((sets) => {
                        this.importSetIdx = 0;
                        this.importSets = sets;
                        this.importSetsLoading = false;
                    });
                }
            },
            _importSet() {
                if (!this.canImport || !this.importBackendSets) return;

                this._select(null);
                this.importPending = true;
                api.loadToolSet(this.importSetIdx, this._importCallback, this._importErrorCallback);
            },
            _importSelectFile() {
                if (!this.canImport) return;
                if (!this.importKey) return;
                this._select(null);
                openFile((data) => {
                    this.importPending = true;
                    api.importTools(this.importKey, data, this._importCallback, this._importErrorCallback);
                });
            },
            _importCallback(project, response) {
                updatedProject(project, response);
                this.importPending = false;
                this.importDialog = false;
            },
            _importErrorCallback() {
                this.importPending = false;
            },
            _select(id) {
                this.selected = (id) ? [id]: [];
            },
            _importIO(isInput) {
                if (this.hasBackendUI) {
                    api.selectIO(this.selectedId, isInput, updatedProject);
                } else {
                    openFile((data) => {
                        api.uploadIO(this.selectedId, isInput, data, updatedProject);
                    });
                }
            },
            _clearIO(isInput) {
                if (!this.selectedId) return;
                editTool(this.selectedId, (tool) => {
                    if (isInput) {
                        tool.input = [];
                    } else {
                        tool.output = [];
                    }
                });
            },

            _changeSelection() {
                if (!this.hasSelection) return;
                if (this.$refs.form) this.$refs.form.resetValidation();

                this.editedItem = cloneDeep(this.selectedItem);
            },
            _updateItem() {
                this.$refs.form.validate();
                this.$nextTick(() => {
                    if (!this.$refs.form.validate()) return;
                    if (!this.formValid) return;

                    const item = this.editedItem;
                    editTool(this.selectedId, (tool) => {
                        tool.name = item.name;
                        if (this.canEditRef) tool.ref = item.ref;
                    });
                });
            },

            _onFileOps() {
                this._select(null);
                setTimeout(() => {
                    this.$refs.tree.updateAll(true);
                }, 100);
            },
        },
        created() {
            dispatcher.onFileOps(this._changeSelection);
            dispatcher.onUndoRedo(this._changeSelection);
        },
    });
</script>

<style scoped>

</style>