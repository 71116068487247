import b64ToBlob from 'b64-to-blob';
import blobToBase64 from 'blob-to-base64';
import {saveAs} from 'file-saver';
import fileDialog from "file-dialog";
import {FileContent} from "./api";

export function saveFileContentCallback(fileContent: FileContent) {
    saveEncodedFile(fileContent.filename, fileContent.data, fileContent.mimeType);
}

export function saveEncodedFile(name: string, data: string, mimeType: string): void {
    const blob = b64ToBlob(data, mimeType);
    saveBlob(name, blob);
}

export function saveFile(name: string, data: string, mimeType: string): void {
    const byteArray = (new TextEncoder()).encode(data);
    const blob = new Blob([byteArray], { type: mimeType });
    saveBlob(name, blob);
}

export function saveBlob(name: string, blob: Blob): void {
    saveAs(blob, name);
}

export function saveDataUrl(name: string, url: string) {
    saveAs(url, name);
}

export function openFile(callback: (data: string, filename: string) => void): void {
    fileDialog().then((files: FileList) => {
        blobToBase64(files[0], (err, base64Data) => {
            if (err) {
                console.log(err);
                return;
            }
            if (!base64Data) return;

            // base64 string starts with: data:...;base64,...
            base64Data = base64Data.split(';base64,', 2)[1];
            callback(base64Data, files[0].name);
        });
    });
}
