
export enum OperationType {
    READ = "READ",
    WRITE = "WRITE",
    CREATE_NODE = 'CREATE_NODE',
    WRITE_NR_INST = 'WRITE_NR_INST',
}

export enum NoneActionType {
    REMOVE_NODE = 'REMOVE_NODE',
    EMPTY_NODE = 'EMPTY_NODE',
    DO_NOTHING = 'DO_NOTHING',
}

export enum MultiActionType {
    VALUE_LIST = 'VALUE_LIST',
    NODE_COPIES = 'NODE_COPIES',
}

export interface Operation {
    type: OperationType;
    dataXpath?: string|null;
    dataId?: number|null;
    noneAction: NoneActionType;
    multiAction: MultiActionType;
}

export interface Component {
    id: number;
    name: string;
    qois: number[];
    ops?: Operation|null;
    ref?: string|null;
}

export interface QOI {
    id: number;
    name: string;
    isOutput: boolean;
    ops?: Operation|null;
    value?: string|number;
    ref?: string|null;
}

export interface Data {
    id: number;
    name: string;
    attr: {[key: string]: string|null};
    xpath?: string|null;
    children: Data[];
    toolIds: number[];
    ref?: string|null;
}

export interface Tool {
    id: number;
    name: string;
    input: Data[];
    output: Data[];
    selected: boolean;
    ref?: string|null;
}

export interface CombinedData {
    input: Data[];
    output: Data[];
}

export interface Mappings {
    componentQois: {[key: number]: number[]};
    qoiComponents: {[key: number]: number[]};
    qoiData: {[key: number]: number};
    dataQois: {[key: number]: number[]};
    toolQois: {[key: number]: number[]};
    qoiTools: {[key: number]: number[]};
    componentTools: {[key: number]: number[]};
    toolComponents: {[key: number]: number[]};
}

export interface Project {
    name: string;
    components: Component[];
    qois: QOI[];
    tools: Tool[];
    data: CombinedData;
    mappings: Mappings;
    ref?: string|null;
}

export type idType = number;

// Architecture instance data model

export interface DataInstance {
    name: string,
    attr: {[key: string]: string|null};
    value?: string;
    children: Data[];
}

export interface QOIInstance {
    qoi: QOI,
    value?: string|number;
}

export interface ComponentInstance {
    component: Component;
    qoiInstances: QOIInstance[];
}

export interface ArchitectureInstanceData {
    components: ComponentInstance[];
    qois: QOIInstance[];
    isInput: boolean;
}
