import Vue from 'vue';
import filter from 'lodash/filter';
import {idType} from "./model/model";
import {store, getNextId} from './store';

type KeyPressCallback = (e: KeyboardEvent, doAction: boolean) => void;

export default class Dispatcher {
    private vue: Vue;
    private keyPressHandlers: KeyPressCallback[] = [];

    constructor() {
        this.vue = new Vue();
    }

    /*
     Use arrow functions to make instance-specific function that are automatically bound to the class instance.
     Note: this won't work with inheritance!
     For more info see: https://cmichel.io/es6-class-methods-differences/
     */

    success = (msg: string) => { this.vue.$emit('success', msg); };
    error = (msg: string) => { this.vue.$emit('error', msg); };
    onSuccess = (callback: (msg: string) => void) => { this.vue.$on('success', callback); };
    onError = (callback: (msg: string) => void) => { this.vue.$on('error', callback); };

    isLoading = (loading: boolean) => { this.vue.$emit('loading', loading); };
    onIsLoading = (callback: (loading: boolean) => void) => { this.vue.$on('loading', callback); };

    undoRedo = () => { this.vue.$emit('undo-redo'); };
    onUndoRedo = (callback: () => void) => { this.vue.$on('undo-redo', callback); };

    fileOps = () => { this.vue.$emit('file-ops'); };
    onFileOps = (callback: () => void) => { this.vue.$on('file-ops', callback); };

    handleKeyPress = (e: KeyboardEvent, doAction: boolean): boolean => {
        const handlers = this.keyPressHandlers;
        for (let i = 0; i < handlers.length; i++) {
            handlers[i](e, doAction);
            if (e.defaultPrevented) return true;
        }
        return false;
    };
    onKeyPress = (callback: KeyPressCallback) => { this.keyPressHandlers.push(callback); };
    offKeyPress = (callback: KeyPressCallback) => {
        this.keyPressHandlers = filter(this.keyPressHandlers, (handler) => handler != callback);
    };

    getNextId = () => getNextId();
    setNextId = (nextId: idType) => { store.commit.commitOverrideNextId(nextId); };

    setAuthStatus = (isAuth: boolean|string, selectedScope: string|null) => {
        store.commit.commitAuthState({isAuth, selectedScope}) };

    displayAbout = () => { this.vue.$emit('display-about'); };
    onDisplayAbout = (callback: () => void) => { this.vue.$on('display-about', callback); };

    updatedBackendState = () => { store.dispatch.updateBackendState(); };
}
