<template>
    <div class="fill-height" @mouseover="_hoverOut">
        <div class="d-flex align-center justify-center fill-height matrix-wrapper">
            <!-- No mappings yet --->
            <div class="flex flex-grow-0 text-center" v-if="!hasTools || !hasQOIs">
                <h2 class="font-weight-light mb-4">
                    <span v-if="!hasTools && !hasQOIs">No Quantities of Interest and tools defined yet...</span>
                    <span v-else-if="!hasQOIs">No Quantities of Interest defined yet...</span>
                    <span v-else>No tools defined yet...</span>
                </h2>

                <v-btn
                    v-if="!hasQOIs"
                    color="primary"
                    @click="_editComps"
                ><v-icon left>{{ mdiCog }}</v-icon>Edit components & QOIs</v-btn>
                <v-btn
                    v-if="!hasTools"
                    color="primary"
                    :class="{'ml-2': !hasQOIs}"
                    @click="_editTools"
                ><v-icon left>{{ mdiCalculator }}</v-icon>Edit tools</v-btn>
            </div>

            <!-- Buttons -->
            <div class="buttons mt-4" v-if="showMatrix">
                <v-menu offset-y>
                    <template v-slot:activator="{on}">
                        <v-btn color="accent" v-on="on"><v-icon left>{{ mdiExport }}</v-icon>Export</v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="type in ExportTypes"
                                :key="type"
                                @click="_export(type)"
                        ><v-list-item-title>{{ exportTypeTitles[type] }}</v-list-item-title></v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <!-- Mapping matrix -->
            <div class="fill-height matrix-scroll-wrapper" v-if="showMatrix">
                <div class="matrix">
                    <div class="wrapper">
                        <table ref="matrixTable">
                            <tbody>
                                <!-- Upper header rows -->
                                <tr>
                                    <td colspan="2" style="border: none"></td>
                                    <td :colspan="toolsMapped.length" class="text-center seg-title">Tools</td>
                                </tr>
                                <tr class="header">
                                    <td class="text-center comp-qoi-title seg-title" style="border-right: none"
                                    >Components</td>
                                    <td class="text-center comp-qoi-title seg-title">QOIs</td>
                                    <td
                                        v-for="(tool, idx) in toolsMapped"
                                        :class="{'not-mapped': !tool.mapped, 'hover': hoverCol === idx}"
                                        class="tool-name"
                                        @mouseover.stop="_hover(null, idx)"
                                    ><div :style="{
                                        'height': toolNameSizes[idx].height,
                                        'width': toolNameSizes[idx].width,
                                    }">
                                        <div class="tool-name-label-wrapper">
                                            <div
                                                class="tool-name-label-pos"
                                                :style="{
                                                    'left': toolNameSizes[idx].left,
                                                    'top': toolNameSizes[idx].top,
                                                    'transform': toolNameSizes[idx].transform,
                                                    'width': toolNameSizes[idx].labelWidth,
                                                }"
                                            >
                                                <div
                                                    class="tool-name-label"
                                                    ref="toolNameLabel"
                                                >{{ tool.name }}</div></div>
                                        </div>
                                    </div></td>
                                </tr>

                                <!-- Component & QOI rows -->
                                <tr
                                    v-for="({ comp, qoi, mapped, toolsMap }, rowIdx) in compQois"
                                    :class="{'not-mapped': !mapped}"
                                >
                                    <!-- Component & QOI columns -->
                                    <td
                                        class="header"
                                        :class="{'hover': hoverRow === rowIdx}"
                                        @mouseover.stop="_hover(rowIdx, null)"
                                    ><span v-if="comp">{{ comp.name }}</span></td>
                                    <td
                                        class="header"
                                        :class="{'hover': hoverRow === rowIdx}"
                                        @mouseover.stop="_hover(rowIdx, null)"
                                    ><span v-if="qoi">{{ qoi.name }}</span></td>

                                    <!-- Mapping columns -->
                                    <td
                                        v-for="({ qoiMapped, mapped }, colIdx) in toolsMap"
                                        class="mapping-cell"
                                        :class="{
                                            'not-mapped': !mapped && !qoiMapped,
                                            'mapped': qoiMapped,
                                            'hover': (hoverCol === colIdx) || (hoverRow === rowIdx),
                                            'hover-target': (hoverCol === colIdx) && (hoverRow === rowIdx),
                                        }"
                                        @mouseover.stop="_hover(rowIdx, colIdx)"
                                    ><v-icon v-if="qoiMapped" color="green darken-2">{{ mdiCheckBold }}</v-icon></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store} from '@/store';
    import {mdiCog, mdiCalculator, mdiCheckBold, mdiExport} from '@mdi/js';
    import {ExportTypes, exportTypeTitles, exportMatrix} from "@/export";

    import map from 'lodash/map';
    import filter from 'lodash/filter';
    import assign from 'lodash/assign';
    import sortBy from 'lodash/sortBy';
    import concat from 'lodash/concat';
    import includes from 'lodash/includes';

    export default Vue.extend({
        name: "matrix",
        data: () => ({
            mdiCog, mdiCalculator, mdiCheckBold, mdiExport,
            ExportTypes, exportTypeTitles,

            toolNameHeight: 24,
            toolNameWidths: null,
            rotationAngle: -70,

            hoverRow: null,
            hoverCol: null,
            disableEvents: false,
        }),
        watch: {
            tools: {
                handler() {
                    this.$nextTick(() => this._updateToolNameWidths());
                },
                deep: true,
            },
        },
        computed: {
            project: () => store.state.localProject,
            hasTools() {
                return this.project.tools.length > 0;
            },
            hasQOIs() {
                return this.project.qois.length > 0;
            },
            showMatrix() {
                return this.hasTools && this.hasQOIs;
            },
            mappings() {
                return this.project.mappings;
            },
            tools() {
                return sortBy(this.project.tools, 'name');
            },
            toolsMapped() {
                const mappings = this.mappings;
                const toolQois = mappings.toolQois;
                const toolComps = mappings.toolComponents;
                return map(this.tools, (tool) => assign({}, tool, {
                    mapped: (tool.id in toolQois) || (tool.id in toolComps),
                }));
            },
            components() {
                return sortBy(this.project.components, 'name');
            },
            qois() {
                return sortBy(this.project.qois, 'name');
            },
            compQois() {
                const components = this.components;
                const qois = this.qois;
                const seenCompIds = [];
                const addedCompIds = [];

                const mappings = this.mappings;
                const compTools = mappings.componentTools;
                const qoiTools = mappings.qoiTools;
                const toolsMapped = this.toolsMapped;

                function getQoiToolsMap(id) {
                    return map(toolsMapped, (tool) => ({
                        qoiMapped: includes(qoiTools[id] || [], tool.id),
                        mapped: tool.mapped,
                    }));
                }

                function getRows(isOutput) {
                    const rows = [];
                    const seenQoiIds = [];

                    for (const comp of components) {
                        const compQois = filter(qois, (qoi) => qoi.isOutput === isOutput && includes(comp.qois, qoi.id));
                        if (!isOutput && !includes(addedCompIds, comp.id)) {
                            rows.push({
                                comp,
                                qoi: null,
                                mapped: comp.id in compTools,
                                toolsMap: map(toolsMapped, (tool) => ({
                                    qoiMapped: includes(compTools[comp.id] || [], tool.id),
                                    mapped: tool.mapped,
                                })),
                            });
                            addedCompIds.push(comp.id);
                        }
                        for (const qoi of compQois) {
                            seenQoiIds.push(qoi.id);
                            rows.push({
                                comp,
                                qoi,
                                mapped: qoi.id in qoiTools,
                                toolsMap: getQoiToolsMap(qoi.id),
                            });
                        }
                        if (compQois.length > 0 || !!comp.ops) seenCompIds.push(comp.id);
                    }

                    for (const qoi of qois) {
                        if (qoi.isOutput !== isOutput) continue;
                        if (!includes(seenQoiIds, qoi.id)) rows.push({
                            comp: null,
                            qoi,
                            mapped: qoi.id in qoiTools,
                            toolsMap: getQoiToolsMap(qoi.id),
                        });
                    }

                    return rows;
                }
                const compQoiRows = concat(
                    getRows(false),
                    getRows(true),
                );

                for (const comp of components) {
                    if (!includes(seenCompIds, comp.id)) compQoiRows.push({
                        comp,
                        qoi: null,
                        mapped: false,
                        toolsMap: map(toolsMapped, (tool) => ({
                            qoiMapped: false,
                            mapped: tool.mapped,
                        })),
                    });
                }
                return compQoiRows;
            },
            toolNameSizes() {
                const h = this.toolNameHeight;
                const w = this.toolNameWidths;

                if (!w || w[0] === undefined) return map(this.toolsMapped, () => ({
                    width: h.toString()+'px',
                    height: h.toString()+'px',
                    left: (-.5*h).toString()+'px',
                    top: (-.5*h).toString()+'px',
                    transform: '',
                    labelWidth: 'auto',
                }));

                const maxWidth = Math.max(...w);
                const rotAngle = this.rotationAngle;
                const angleRad = Math.abs(rotAngle*(Math.PI/180));
                return map(this.toolsMapped, (_, i) => {
                    const labelWidth = w[i];
                    return {
                        transform: 'rotate('+rotAngle.toString()+'deg)',
                        width: (Math.cos(angleRad)*labelWidth + Math.sin(angleRad)*h).toString()+'px',
                        height: (Math.sin(angleRad)*labelWidth + Math.cos(angleRad)*h).toString()+'px',
                        labelWidth: labelWidth.toString()+'px',
                        left: (-.5*labelWidth).toString()+'px',
                        top: (-.5*h+.5*Math.sin(angleRad)*(maxWidth-w[i])).toString()+'px',
                    };
                });
            },
        },
        methods: {
            _editComps() {
                this.$emit('comps');
            },
            _editTools() {
                this.$emit('tools');
            },
            _updateToolNameWidths() {
                this.toolNameWidths = map(this.$refs.toolNameLabel, (labelEl) => {
                    return labelEl.offsetWidth;
                });
            },
            _hover(rowIdx, colIdx) {
                if (this.disableEvents) return;
                this.hoverRow = rowIdx;
                this.hoverCol = colIdx;
            },
            _hoverOut() {
                if (this.disableEvents) return;
                this.hoverRow = null;
                this.hoverCol = null;
            },
            _export(type) {
                this.disableEvents = true;
                const hoverRow = this.hoverRow;
                const hoverCol = this.hoverCol;

                exportMatrix(this.$refs.matrixTable, type, this.project.name, this.rotationAngle, () => {
                    this.hoverRow = hoverRow;
                    this.hoverCol = hoverCol;
                    this.disableEvents = false;
                });
            },
        },
        mounted() {
            this.$nextTick(() => this._updateToolNameWidths());
        },
    });
</script>

<style scoped>
    .matrix-wrapper {
        position: relative;
    }
    .matrix-wrapper .buttons {
        position: absolute;
        top: 0;
        z-index: 100;
    }

    .matrix-scroll-wrapper {
        width: 100%;
        overflow: auto;
        text-align: center;
    }
    .matrix {
        line-height: 1.5;
        max-width: 100%;
        padding: 60px 0 30px 0;
        display: inline-block;
    }
    .matrix > .wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .matrix table {
        width: 100%;
        border-spacing: 0;
    }
    .matrix table > tbody > tr > td {
        padding: 4px 12px;
    }
    .matrix table > tbody > tr:not(:last-child) > td {
        border-bottom: thin solid rgba(0, 0, 0, .12);
    }
    .matrix table > tbody > tr > td:not(:last-child) {
        border-right: thin solid rgba(0, 0, 0, .12);
    }

    .matrix table td.seg-title {
        font-weight: bold;
    }
    .matrix table > tbody > tr.header > td {
        border-bottom-color: rgba(0, 0, 0, .40);
        border-bottom-width: 2px;
    }
    .matrix table > tbody > tr > td.header:nth-child(2) {
        border-right-color: rgba(0, 0, 0, .40);
        border-right-width: 2px;
    }
    .matrix table td.mapping-cell {
        text-align: center;
    }
    .matrix table .comp-qoi-title {
        font-weight: normal;
        vertical-align: bottom;
    }

    .matrix table tr.not-mapped,
    .matrix table td.not-mapped {
        background-color: #FFCDD2;
    }
    .matrix table td.mapped {
        background-color: #C8E6C9;
    }

    .matrix table td.hover {
        background-color: #B0BEC5;
    }
    .matrix table tr.not-mapped td.hover,
    .matrix table td.not-mapped.hover {
        background-color: #EF9A9A;
    }
    .matrix table td.mapped.hover {
        background-color: #A5D6A7;
    }

    .matrix table td.hover.hover-target {
        background-color: #78909C;
    }
    .matrix table tr.not-mapped td.hover.hover-target,
    .matrix table td.not-mapped.hover.hover-target {
        background-color: #EF5350;
    }
    .matrix table td.mapped.hover.hover-target {
        background-color: #66BB6A;
    }

    .matrix table td.tool-name > div {
        position: relative;
    }
    .matrix table td.tool-name div.tool-name-label-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
    }
    .matrix table td.tool-name div.tool-name-label-pos {
        position: absolute;
    }
    .matrix table td.tool-name div.tool-name-label {
        white-space: nowrap;
        display: inline-block;
    }
</style>