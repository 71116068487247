<template>
    <div class="fill-height">
        <div class="fill-height d-flex align-stretch">
            <!-- Left pane -->
            <div class="col-3 pa-0 scroll">
                <v-container fluid>
                    <!-- Add buttons -->
                    <div class="d-flex justify-center mb-3" v-if="editable">
                        <div>
                            <v-btn
                                v-if="canAddRemove"
                                :color="(canImport) ? null: 'primary'"
                                @click="_addComponent"
                            ><v-icon left>{{ mdiPlus }}</v-icon>Component</v-btn>
                            <v-btn
                                v-if="canAddRemove"
                                :color="(canImport) ? null: 'primary'"
                                class="ml-2"
                                @click="_addQOI"
                            ><v-icon left>{{ mdiPlus }}</v-icon>QOI</v-btn>

                            <v-btn
                                v-if="canImport"
                                color="primary"
                                :class="{'ml-2': canAddRemove}"
                                @click="_import"
                            ><v-icon left>{{ mdiUpload }}</v-icon>Import</v-btn>
                        </div>
                    </div>

                    <v-treeview
                        :active.sync="selected"
                        activatable multiple-active hoverable open-all
                        :items="items"
                        @update:active="_selectionUpdate"
                        class="mx-n3 pointer"
                        ref="tree"
                    >
                        <template v-slot:prepend="{ item }">
                            <v-icon v-if="item.isComp">{{ mdiCog }}</v-icon>
                            <v-icon v-else-if="item.isOutput">{{ mdiChartLine }}</v-icon>
                            <v-icon v-else>{{ mdiTuneVertical }}</v-icon>
                        </template>
                        <template v-slot:append="{ item }">
                            <v-icon v-if="item.isMapped"
                                    :color="(item.isMapped === 2) ? 'warning': 'success'"
                                    :title="(item.isMapped === 2) ? 'Partly connected to data schema': 'Connected to data schema'"
                            >{{ mdiCheckCircle }}</v-icon>
                            <v-icon v-else
                                    color="red lighten-4"
                                    title="Not connected to data schema"
                            >{{ mdiLinkOff }}</v-icon>

                            <v-btn icon v-if="canAddRemove" class="ml-2"
                                   @click.stop="_deleteItem(item.id, item.isComp)"
                            ><v-icon>{{ mdiTrashCan }}</v-icon></v-btn>
                        </template>
                    </v-treeview>
                </v-container>
            </div>

            <v-divider vertical v-if="hasSelection" />

            <!-- Main screen -->
            <div class="col-3 pa-0 scroll">
                <v-container fluid v-if="hasSelection">
                    <h1>Editing <span v-if="isQOI">Quantity of Interest</span><span v-else>Component</span></h1>

                    <v-form v-model="formValid" ref="form" @submit.prevent="">
                        <!-- Name field -->
                        <v-text-field
                            v-model="editedItem.name"
                            :disabled="!canEditProps"
                            label="Name"
                            @blur="_updateItem"
                            @keyup.enter.stop="_updateItem"
                            :rules="[rules.required]"
                        ></v-text-field>

                        <!-- Ref field -->
                        <v-text-field
                            v-model="editedItem.ref"
                            :disabled="!canEditRef"
                            label="External reference"
                            hide-details="auto"
                            @blur="_updateItem"
                            @keyup.enter.prevent="_updateItem"
                        ></v-text-field>

                        <!-- Is output switch -->
                        <v-switch
                            v-if="isQOI"
                            v-model="editedItem.isOutput"
                            @change="_updateItem"
                            :disabled="!canEditProps"
                            label="Is output value"
                        />

                        <!-- Value field -->
                        <v-text-field
                            v-if="isQOI && !editedItem.isOutput"
                            v-model="editedItem.value"
                            :disabled="!canEditProps"
                            label="Fixed input value"
                            hide-details="auto"
                            @blur="_updateItem"
                            @keyup.enter.prevent="_updateItem"
                        ></v-text-field>

                        <!-- Has DSO switch -->
                        <v-switch
                            v-if="!isQOI"
                            v-model="hasOps"
                            @change="_toggleCompOps"
                            :disabled="!canEditOps"
                            label="Has DSO (i.e. influences the data schema)"
                        />

                        <!-- Assignment -->
                        <v-autocomplete
                            v-model="assignmentSelected"
                            :label="(isQOI) ? 'Assigned to component(s)': 'Assigned QOIs'"
                            clearable multiple chips deletable-chips
                            :append-icon="null"
                            :items="assignmentItems"
                            :disabled="!canEditProps"
                            @blur="_updateAssignment"
                            @keyup.enter.prevent="_updateAssignment"
                        />
                    </v-form>

                    <!-- The purpose of this button is to make the user deselect the field to trigger an update -->
                    <div class="text-center mt-6" v-if="canEditProps">
                        <v-btn color="primary" :disabled="!formValid">Update</v-btn>
                    </div>
                </v-container>
            </div>

            <v-divider vertical v-if="hasSelection && isQOI" />

            <!-- Operation editing screen -->
            <div class="col pa-0 scroll">
                <v-container fluid v-if="hasSelection && editedOps !== null">
                    <h2>Data Schema Operation (DSO)</h2>

                    <v-alert border="left" colored-border type="info" elevation="2" class="mt-2 grey--text" style="font-size: small">
                        The data operation determines <strong>how</strong> an architecture element influences the data schema.<br />

                        <div v-if="isQOI">
                            For this Quantity of Interest (QOI), it determines how to
                            <span v-if="editedItem.isOutput"><em>read</em> the value(s) from the data schema.</span>
                            <span v-else><em>write</em> the value(s) to the data schema.</span>
                        </div>
                        <div v-else>
                            For this Component, it determines how to update the data schema.
                        </div>
                    </v-alert>

                    <!-- Input operations -->
                    <ops
                        v-model="editedOps"
                        :is-output="(isQOI) ? editedItem.isOutput: false"
                        :is-qoi="isQOI"
                        :editable="canEditOps"
                        @input="_updateOps"
                    />

                </v-container>
            </div>
        </div>

        <!-- Import dialog -->
        <v-dialog
            v-model="importDialog"
            max-width="800"
            @click:outside="importDialog = false"
        >
            <v-card :loading="importPending || importSetsLoading">
                <v-card-title class="headline text-center">Import Components & QOIs</v-card-title>

                <!-- Import from a list of available sets -->
                <v-card-text v-if="importBackendSets">
                    <v-radio-group v-if="importSets.length > 0" v-model="importSetIdx">
                        <v-radio v-for="(title, idx) in importSets" :key="idx" :value="idx">
                            <template v-slot:label>
                                <div>{{ title }}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <div v-else class="text-center grey--text">Nothing to import...</div>
                </v-card-text>

                <!-- Import from file -->
                <v-card-text v-else>
                    <v-radio-group v-model="importKey">
                        <v-radio v-for="(value, key) in importKeys" :key="key" :value="key">
                            <template v-slot:label>
                                <div>
                                    <span style="font-weight: bold">{{ value[0] }}: </span>
                                    <span style="font-size: small">{{ value[1] }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="importDialog = false">Close</v-btn>

                    <v-btn v-if="importBackendSets" text color="primary" @click="_importSet"
                           :disabled="importSetIdx === null || importSets.length === 0">Import</v-btn>
                    <v-btn v-else text color="primary" @click="_importSelectFile"
                           :disabled="!importKey">Select File</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from "vue";
    import {store, updatedProject} from '@/store';
    import {api, dispatcher} from "@/main";
    import {getName, addComponent, addQOI, deleteComponent, deleteQOI, editComponent, editQOI,
        assignQOI} from "@/model/model-ops";
    import {openFile} from "@/files";

    import {mdiPlus, mdiTrashCan, mdiUpload, mdiCog, mdiChartLine, mdiLinkOff, mdiCheckCircle, mdiTuneVertical} from '@mdi/js';

    import map from 'lodash/map';
    import find from 'lodash/find';
    import uniq from 'lodash/uniq';
    import some from 'lodash/some';
    import every from 'lodash/every';
    import clone from 'lodash/clone';
    import sortBy from 'lodash/sortBy';
    import concat from 'lodash/concat';
    import filter from 'lodash/filter';
    import flatten from 'lodash/flatten';
    import includes from 'lodash/includes';
    import cloneDeep from 'lodash/cloneDeep';
    import fromPairs from 'lodash/fromPairs';
    import difference from 'lodash/difference';

    import ops from './ops.vue';

    export default Vue.extend({
        name: "compqoi",
        components: {
            ops,
        },
        data: () => ({
            mdiPlus, mdiTrashCan, mdiUpload, mdiCog, mdiChartLine, mdiLinkOff, mdiCheckCircle, mdiTuneVertical,

            formValid: false,
            editedItem: {},
            assignmentSelected: [],
            editedOps: null,
            hasOps: false,

            selected: [],
            prevSelected: [],

            importDialog: false,
            importKeys: {
                adore: ['ADORE project (.adore)', 'imports components and QOIs from the design space of an ADORE project.'],
            },
            importKey: 'adore',
            importPending: false,
            importSets: [],
            importSetIdx: null,
            importSetsLoading: false,

            rules: {
                required: (value) => !!value || 'Required',
            },
        }),
        watch: {
            hasSelection() {
                this._changeSelection();
            },
            selectedId() {
                this._changeSelection();
            },
            editedOps(editedOps) {
                this.hasOps = editedOps !== null;
            },
        },
        computed: {
            project: () => store.state.localProject,
            editable: () => store.state.editable,
            canAddRemove() {
                return this.editable && store.state.settings.edit_comp_qoi;
            },
            canEditProps() {
                return this.canAddRemove;
            },
            canImport() {
                return this.editable && store.state.settings.import_comp_qoi;
            },
            importBackendSets() {
                return store.state.settings.db_provides_comp_qoi;
            },
            canEditRef() {
                return this.editable && store.state.settings.edit_refs;
            },
            canEditOps() {
                return this.editable;
            },
            components() {
                return (this.project) ? this.project.components: [];
            },
            qois() {
                return (this.project) ? this.project.qois: [];
            },
            mappings() {
                return (this.project) ? this.project.mappings: {};
            },
            qoiMap() {
                return fromPairs(map(this.qois, (qoi) => [qoi.id, qoi]));
            },
            compQoiIds() {
                return uniq(flatten(map(this.components, (comp) => comp.qois)));
            },
            standaloneQoiIds() {
                const compQoiIds = this.compQoiIds;
                return filter(map(this.qois, (qoi) => qoi.id), (qoiId) => !includes(compQoiIds, qoiId));
            },
            items() {
                const qoiMap = this.qoiMap;
                const itemIds = [];
                let nextItemId = this.components.length+this.qois.length+100;
                const qoiDataMap = this.mappings.qoiData || {};
                const compToolMap = this.mappings.componentTools || {};

                function getItemId(id) {
                    if (!includes(itemIds, id)) {
                        itemIds.push(id);
                        return id;
                    }

                    const nextId = nextItemId;
                    nextItemId++;
                    return nextId;
                }

                function renderQoiIds(qoiIds) {
                    return sortBy(map(qoiIds, (qoiId) => {
                        const qoi = qoiMap[qoiId];
                        return {
                            id: getItemId(qoi.id),
                            origId: qoi.id,
                            name: qoi.name,
                            isComp: false, isQoi: true,
                            isOutput: qoi.isOutput,
                            isMapped: qoi.id in qoiDataMap,
                        }
                    }), 'name');
                }

                return concat(
                    sortBy(map(this.components, (comp) => {
                        const qoiMapped = map(comp.qois, (qoiId) => qoiId in qoiDataMap);
                        const compMapped = comp.id in compToolMap;
                        const anyMapped = (qoiMapped.length > 0 || compMapped) ? some(qoiMapped) || (compMapped && comp.qois.length > 0): false;
                        const allMapped = (qoiMapped.length > 0 || compMapped) ? every(qoiMapped) || (compMapped && comp.qois.length === 0): false;

                        return {
                            id: getItemId(comp.id),
                            origId: comp.id,
                            name: comp.name,
                            isComp: true, isQoi: false,
                            isOutput: false,
                            children: renderQoiIds(comp.qois),
                            isMapped: (allMapped) ? true: (anyMapped) ? 2: false,
                        }
                    }), 'name'),
                    renderQoiIds(this.standaloneQoiIds),
                );
            },
            derivedIds() {
                const derivedMap = {};

                function processItems(items) {
                    for (const item of items) {
                        if (item.id !== item.origId) {
                            if (!(item.origId in derivedMap)) derivedMap[item.origId] = [];
                            derivedMap[item.origId].push(item.id);
                        }

                        if (item.children) processItems(item.children);
                    }
                }

                processItems(this.items);
                return derivedMap;
            },
            origIdMap() {
                return fromPairs(flatten(map(this.derivedIds, (derivedIds, origId) =>
                    map(derivedIds, (derivedId) => ([derivedId, parseInt(origId)])))));
            },

            selectedId() {
                return (this.selected.length > 0) ? this.selected[0]: null;
            },
            hasSelection() {
                return this.selectedId !== null;
            },
            isQOI() {
                return this.selectedId in this.qoiMap;
            },
            isComp() {
                return !this.isQOI;
            },
            selectedItem() {
                if (!this.hasSelection) return {};
                const selectedId = this.selectedId;
                if (this.isQOI) return this.qoiMap[selectedId];
                return find(this.components, (comp) => comp.id === selectedId) || {};
            },
            assignmentItems() {
                if (!this.hasSelection) return [];
                if (this.isQOI) {
                    return sortBy(map(this.components, (comp) => ({ value: comp.id, text: comp.name })), 'text');
                } else {
                    return sortBy(map(this.qois, (qoi) => ({ value: qoi.id, text: qoi.name })), 'text');
                }
            },
        },
        methods: {
            _addComponent() {
                const compNames = map(this.components, (comp) => comp.name);
                const comp = addComponent(getName('New component', compNames));
                this._select(comp.id);
            },
            _addQOI() {
                const qoiNames = map(this.qois, (qoi) => qoi.name);
                const qoi = addQOI(getName('New QOI', qoiNames));
                this._select(qoi.id);
            },
            _deleteItem(id, isComp) {
                if (isComp) {
                    deleteComponent(id);
                } else {
                    deleteQOI(id);
                }
                if (this.selectedId === id) this._select(null);
            },
            _import() {
                if (!this.canImport) return;
                this.importPending = false;
                this.importDialog = true;

                if (this.importBackendSets) {
                    this.importSets = [];
                    this.importSetIdx = null;
                    this.importSetsLoading = true;
                    api.getCompQoiSets((sets) => {
                        this.importSetIdx = 0;
                        this.importSets = sets;
                        this.importSetsLoading = false;
                    });
                }
            },
            _importSet() {
                if (!this.canImport || !this.importBackendSets) return;

                this._select(null);
                this.importPending = true;
                api.loadCompQoiSet(this.importSetIdx, this._importCallback, this._importErrorCallback);
            },
            _importSelectFile() {
                if (!this.canImport || this.importBackendSets) return;
                if (!this.importKey) return;
                this._select(null);
                openFile((data) => {
                    this.importPending = true;
                    api.importCompQoi(this.importKey, data, this._importCallback, this._importErrorCallback);
                });
            },
            _importCallback(project, response) {
                updatedProject(project, response);
                this.importPending = false;
                this.importDialog = false;
            },
            _importErrorCallback() {
                this.importPending = false;
            },
            _select(id) {
                this.selected = (id) ? [id]: [];
            },

            _selectionUpdate(newSelected) {
                // In the tree, multiple items can appear that reference the same element in the model (because a QOI
                // can be assigned to multiple components); this function implements the selection logic in the treeview

                const derivedIds = this.derivedIds;
                const origIdMap = this.origIdMap;

                // Get previously selected original item IDs
                let selectedOrigIds = filter(this.prevSelected, (id) => !(id in origIdMap));
                const prevSelectedOrigIds = clone(selectedOrigIds);

                function getOrigId(id) {
                    return (id in origIdMap) ? origIdMap[id]: id;
                }

                // Determine original item IDs of newly selected items
                const addedIds = difference(newSelected, this.prevSelected);
                for (const addedId of addedIds) {
                    selectedOrigIds.push(getOrigId(addedId));
                }
                selectedOrigIds = uniq(selectedOrigIds);

                // Replace selection of original ID if new one is added
                const newOrigIds = difference(selectedOrigIds, prevSelectedOrigIds);
                if (newOrigIds.length > 0) selectedOrigIds = newOrigIds;

                // Remove original item IDs of newly unselected items
                const removedIds = difference(this.prevSelected, newSelected);
                for (const removedId of removedIds) {
                    const origRemovedId = getOrigId(removedId);
                    selectedOrigIds = filter(selectedOrigIds, (id) => id !== origRemovedId);
                }

                // Extend selected list with derived IDs
                const selectedUpdated = clone(selectedOrigIds);
                for (const id of selectedOrigIds) {
                    const derivedOrigIds = (id in derivedIds) ? derivedIds[id]: [];
                    for (const derivedId of derivedOrigIds) {
                        selectedUpdated.push(derivedId);
                    }
                }

                this.prevSelected = selectedUpdated;
                this.selected = selectedUpdated;
            },
            _changeSelection() {
                if (!this.hasSelection) return;
                if (this.$refs.form) this.$refs.form.resetValidation();

                this._updateEditedItem();
                if (this.isQOI) {
                    const qoiId = this.selectedItem.id;
                    this.assignmentSelected = map(filter(this.components,
                        (comp) => includes(comp.qois, qoiId)), (comp) => comp.id);
                    this.editedOps = cloneDeep(this.selectedItem.ops);
                } else {
                    this.assignmentSelected = cloneDeep(this.selectedItem.qois);
                    this.editedOps = (!!this.selectedItem.ops) ? cloneDeep(this.selectedItem.ops): null;
                }
            },
            _updateEditedItem() {
                this.editedItem = cloneDeep(this.selectedItem);
                this.editedItem.value = (this.editedItem.value) ? this.editedItem.value.toString(): '';
            },
            _updateItem() {
                function parseFloatValue(value) {
                    if (!value) return undefined;
                    const floatValue = parseFloat(value);
                    return (isNaN(floatValue)) ? value: floatValue;
                }

                this.$refs.form.validate();
                this.$nextTick(() => {
                    if (!this.$refs.form.validate()) return;
                    if (!this.formValid) return;

                    const item = this.editedItem;
                    if (this.isQOI) {
                        editQOI(this.selectedId, (qoi) => {
                            qoi.name = item.name;
                            qoi.isOutput = item.isOutput;
                            qoi.value = (qoi.isOutput) ? undefined: parseFloatValue(item.value);
                            if (this.canEditRef) qoi.ref = item.ref;
                        }, () => {
                            this._updateEditedItem();
                        });
                    } else {
                        editComponent(this.selectedId, (comp) => {
                            comp.name = item.name;
                            if (this.canEditRef) comp.ref = item.ref;
                        }, () => {
                            this._updateEditedItem();
                        });
                    }
                });
            },
            _updateAssignment() {
                if (this.isQOI) {
                    assignQOI(this.selectedId, this.assignmentSelected);
                } else {
                    editComponent(this.selectedId, (comp) => {
                        comp.qois = this.assignmentSelected;
                    });
                }
                this._selectionUpdate(this.prevSelected);
            },
            _toggleCompOps() {
                this.editedOps = (this.hasOps) ? {}: null;
                this.$nextTick(() => {
                    this._updateOps();
                })
            },
            _updateOps() {
                if (this.isQOI) {
                    editQOI(this.selectedId, (qoi) => {
                        qoi.ops = cloneDeep(this.editedOps);
                    }, (qoi) => {
                        this.editedOps = cloneDeep(qoi.ops);
                    });
                } else {
                    editComponent(this.selectedId, (comp) => {
                        comp.ops = (!!this.editedOps) ? cloneDeep(this.editedOps): undefined;
                    }, (comp) => {
                        this.editedOps = (!!comp.ops) ? cloneDeep(comp.ops): null;
                    });
                }
            },

            _onFileOps() {
                this._select(null);
                setTimeout(() => {
                    this.$refs.tree.updateAll(true);
                }, 100);
            },
        },
        mounted() {
            dispatcher.onFileOps(this._onFileOps);
            dispatcher.onUndoRedo(this._changeSelection);
        },
    });
</script>

<style scoped>

</style>