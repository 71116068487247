var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",on:{"mouseover":_vm._hoverOut}},[_c('div',{staticClass:"d-flex align-center justify-center fill-height matrix-wrapper"},[(!_vm.hasTools || !_vm.hasQOIs)?_c('div',{staticClass:"flex flex-grow-0 text-center"},[_c('h2',{staticClass:"font-weight-light mb-4"},[(!_vm.hasTools && !_vm.hasQOIs)?_c('span',[_vm._v("No Quantities of Interest and tools defined yet...")]):(!_vm.hasQOIs)?_c('span',[_vm._v("No Quantities of Interest defined yet...")]):_c('span',[_vm._v("No tools defined yet...")])]),(!_vm.hasQOIs)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm._editComps}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiCog))]),_vm._v("Edit components & QOIs")],1):_vm._e(),(!_vm.hasTools)?_c('v-btn',{class:{'ml-2': !_vm.hasQOIs},attrs:{"color":"primary"},on:{"click":_vm._editTools}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiCalculator))]),_vm._v("Edit tools")],1):_vm._e()],1):_vm._e(),(_vm.showMatrix)?_c('div',{staticClass:"buttons mt-4"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accent"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiExport))]),_vm._v("Export")],1)]}}],null,false,735572220)},[_c('v-list',_vm._l((_vm.ExportTypes),function(type){return _c('v-list-item',{key:type,on:{"click":function($event){return _vm._export(type)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.exportTypeTitles[type]))])],1)}),1)],1)],1):_vm._e(),(_vm.showMatrix)?_c('div',{staticClass:"fill-height matrix-scroll-wrapper"},[_c('div',{staticClass:"matrix"},[_c('div',{staticClass:"wrapper"},[_c('table',{ref:"matrixTable"},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"border":"none"},attrs:{"colspan":"2"}}),_c('td',{staticClass:"text-center seg-title",attrs:{"colspan":_vm.toolsMapped.length}},[_vm._v("Tools")])]),_c('tr',{staticClass:"header"},[_c('td',{staticClass:"text-center comp-qoi-title seg-title",staticStyle:{"border-right":"none"}},[_vm._v("Components")]),_c('td',{staticClass:"text-center comp-qoi-title seg-title"},[_vm._v("QOIs")]),_vm._l((_vm.toolsMapped),function(tool,idx){return _c('td',{staticClass:"tool-name",class:{'not-mapped': !tool.mapped, 'hover': _vm.hoverCol === idx},on:{"mouseover":function($event){$event.stopPropagation();return _vm._hover(null, idx)}}},[_c('div',{style:({
                                    'height': _vm.toolNameSizes[idx].height,
                                    'width': _vm.toolNameSizes[idx].width,
                                })},[_c('div',{staticClass:"tool-name-label-wrapper"},[_c('div',{staticClass:"tool-name-label-pos",style:({
                                                'left': _vm.toolNameSizes[idx].left,
                                                'top': _vm.toolNameSizes[idx].top,
                                                'transform': _vm.toolNameSizes[idx].transform,
                                                'width': _vm.toolNameSizes[idx].labelWidth,
                                            })},[_c('div',{ref:"toolNameLabel",refInFor:true,staticClass:"tool-name-label"},[_vm._v(_vm._s(tool.name))])])])])])})],2),_vm._l((_vm.compQois),function(ref,rowIdx){
                                            var comp = ref.comp;
                                            var qoi = ref.qoi;
                                            var mapped = ref.mapped;
                                            var toolsMap = ref.toolsMap;
return _c('tr',{class:{'not-mapped': !mapped}},[_c('td',{staticClass:"header",class:{'hover': _vm.hoverRow === rowIdx},on:{"mouseover":function($event){$event.stopPropagation();return _vm._hover(rowIdx, null)}}},[(comp)?_c('span',[_vm._v(_vm._s(comp.name))]):_vm._e()]),_c('td',{staticClass:"header",class:{'hover': _vm.hoverRow === rowIdx},on:{"mouseover":function($event){$event.stopPropagation();return _vm._hover(rowIdx, null)}}},[(qoi)?_c('span',[_vm._v(_vm._s(qoi.name))]):_vm._e()]),_vm._l((toolsMap),function(ref,colIdx){
                                            var qoiMapped = ref.qoiMapped;
                                            var mapped = ref.mapped;
return _c('td',{staticClass:"mapping-cell",class:{
                                        'not-mapped': !mapped && !qoiMapped,
                                        'mapped': qoiMapped,
                                        'hover': (_vm.hoverCol === colIdx) || (_vm.hoverRow === rowIdx),
                                        'hover-target': (_vm.hoverCol === colIdx) && (_vm.hoverRow === rowIdx),
                                    },on:{"mouseover":function($event){$event.stopPropagation();return _vm._hover(rowIdx, colIdx)}}},[(qoiMapped)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(_vm._s(_vm.mdiCheckBold))]):_vm._e()],1)})],2)})],2)])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }