<template>
    <v-app>
        <!-- Main app bar -->
        <v-app-bar
            app dark
            class="main-toolbar"
            :color="mlqDark"
            :min-width="minWidth"
        >
            <!-- Logo -->
            <img
                :src="require('./assets/logo.png')" alt=""
                style="height: 80%;"
                class="mr-4"
            />

            <!-- Project title -->
            <v-toolbar-title class="toolbar-title" @click="_startEditTitle">
                <span title="Click to edit" v-if="!editingTitle">
                    {{ renderProjectTitle }}

                    <!-- Title edit button -->
                    <v-btn icon small class="title-edit-button">
                        <v-icon small>{{ mdiPen }}</v-icon></v-btn>
                </span>
                <span v-else>
                    <!-- Title edit field -->
                    <v-text-field
                            v-model="projectTitle"
                            placeholder="Project name"
                            class="pt-5"
                            ref="titleField"
                            @blur="editingTitle = false"
                            @keyup="_titleKeyUp"
                    />
                </span>
            </v-toolbar-title>

            <v-spacer />

            <!-- Loading indicator (spinner) -->
            <v-progress-circular
                    v-show="loading"
                    indeterminate
                    color="white"
                    size="30"
                    class="mr-8"
            />

            <!-- New file button and confirm dialog -->
            <v-dialog
                    v-model="newProjectDialog"
                    v-if="hasNewProject"
                    max-width="350"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon
                           title="New project (Ctrl+Shift+N)"
                           v-on="on"
                    ><v-icon>{{ mdiFileOutline }}</v-icon></v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline">Start a new project?</v-card-title>
                    <v-card-text>Any unsaved changes will be lost.</v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="newProjectDialog = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="_newProject">New Project</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- File open button -->
            <v-btn icon
                   v-if="hasLoadProject && !hasProjectList"
                   title="Open project (Ctrl+O)"
                   @click="_loadProject"
            ><v-icon>{{ mdiFolderOpenOutline }}</v-icon></v-btn>

            <v-btn icon
                   v-if="hasProjectList"
                   title="Open project list (Ctrl+O)"
                   @click="_openProjectList"
            ><v-icon>{{ mdiFolderOpenOutline }}</v-icon></v-btn>

            <!-- Save button -->
            <v-btn icon
                   v-if="hasSaveProject"
                   title="Save project (Ctrl+S)"
                   :color="(unsaved) ? 'primary': ''"
                   :class="{'mr-4': !hasSaveProjectAs}"
                   @click="_saveProject"
            ><v-icon>{{ mdiContentSaveOutline }}</v-icon></v-btn>

            <!-- Save as button -->
            <v-btn icon
                   v-if="hasSaveProjectAs"
                   title="Save project as (Ctrl+Shift+S)"
                   class="mr-4"
                   :color="(unsaved && !hasSaveProject) ? 'primary': ''"
                   @click="_saveProjectAs"
            ><v-icon>{{ mdiContentSaveEditOutline }}</v-icon></v-btn>

            <!-- Help button -->
            <v-btn icon
                   title="Quick reference (F1)"
                   class="ml-4"
                   @click="_showQuickReference"
            ><v-icon>{{ mdiHelpCircleOutline }}</v-icon></v-btn>

            <!-- About button -->
            <v-btn icon
                   title="About MultiLinQ"
                   @click="_displayAbout"
            ><v-icon>{{ mdiInformationOutline }}</v-icon></v-btn>

            <!-- Logout button -->
            <v-dialog
                    v-model="logoutDialog"
                    v-if="hasAuth"
                    max-width="450"
            >
                <template v-slot:activator="{on}">
                    <v-btn icon
                           :title="'Logout (logged in as: '+authUsername+')'"
                           v-on="on"
                    ><v-icon>{{ mdiLogout }}</v-icon></v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline">Are you sure you want to logout?</v-card-title>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="logoutDialog = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="_logout">Logout</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-app-bar>

        <!-- Main content -->
        <v-main :style="{'min-width': minWidth+'px'}">
            <div class="d-flex flex-column fill-height" style="max-height: 100%; overflow: hidden">
                <v-toolbar
                    height="40"
                    width="100%"
                    :color="mlqLight"
                    class="content-toolbar flex-grow-0"
                >
                    <v-toolbar-items>
                        <!-- Undo/redo -->
                        <v-btn
                                depressed x-small
                                title="Undo (Ctrl+Z)"
                                :disabled="!hasUndo"
                                color="amber lighten-3"
                                @click="_undo"
                        ><v-icon>{{ mdiUndo }}</v-icon></v-btn>
                        <v-btn
                                depressed x-small
                                title="Redo (Ctrl+Y)"
                                class="divide-right"
                                :disabled="!hasRedo"
                                color="amber lighten-3"
                                @click="_redo"
                        ><v-icon>{{ mdiRedo }}</v-icon></v-btn>
                    </v-toolbar-items>

                    <!-- Center page buttons -->
                    <div class="toolbar-center">
                        <div>
                            <!-- Components and QOIs -->
                            <v-btn depressed small title="Edit components and QOIs"
                                   :color="(page === 'comp') ? pageSelColor: pageColor"
                                   @click="page = 'comp'"
                            >
                                <v-icon :left="large">{{ mdiCog }}</v-icon>
                                <span v-if="large">Components & QOIs</span>
                            </v-btn>

                            <!-- Tools -->
                            <v-btn depressed small title="Edit tools"
                                   :class="{'ml-4': large}"
                                   :color="(page === 'tools') ? pageSelColor: pageColor"
                                   @click="page = 'tools'"
                            >
                                <v-icon :left="large">{{ mdiCalculator }}</v-icon>
                                <span v-if="large">Tools</span>
                            </v-btn>

                            <!-- Mapping -->
                            <v-btn depressed small title="Show mapping matrix"
                                   :class="{'ml-4': large}"
                                   :color="(page === null) ? pageSelColor: pageColor"
                                   @click="page = null"
                            >
                                <v-icon :left="large">{{ mdiMatrix }}</v-icon>
                                <span v-if="large">Mapping Matrix</span>
                            </v-btn>

                            <!-- Tools -->
                            <v-btn depressed small title="Verify mapping logic"
                                   :class="{'ml-4': large}"
                                   :color="(page === 'verify') ? pageSelColor: pageColor"
                                   @click="page = 'verify'"
                            >
                                <v-icon :left="large">{{ mdiCheckCircleOutline }}</v-icon>
                                <span v-if="large">Verify Logic</span>
                            </v-btn>
                        </div>
                    </div>

                </v-toolbar>

                <div class="flex-grow-1" style="position: relative;">
                    <div class="content-main-wrapper">
                        <matrix
                            v-if="project && (page === null)"
                            @comps="page = 'comp'"
                            @tools="page = 'tools'"
                        />
                        <compqoi
                            v-if="project"
                            v-show="page === 'comp'"
                        />
                        <tools
                            v-if="project"
                            v-show="page === 'tools'"
                        />
                        <verify
                            v-if="isAuth && project"
                            v-show="page === 'verify'"
                        />
                    </div>
                </div>
            </div>

            <!-- Background logos -->
            <div id="bg-logos" v-show="hasLoaded">
                <img :src="require('./assets/dlr.png')" alt="" />
                <img :src="require('./assets/banner.png')" alt="" />
                <img v-for="filename in logoFiles" :key="filename" alt="" :src="'/logos/'+filename" />
            </div>
        </v-main>

        <!-- Message snackbar (toaster, popup) -->
        <v-snackbar
                v-model="snackbar"
                :timeout="snackbarCloseTimeout"
                :color="snackbarColor"
        >{{ snackbarMsg }}</v-snackbar>

        <!-- Manage the document title -->
        <vue-headful :title="documentTitle" />

        <!-- Authentication popups -->
        <auth v-if="hasAuth" :showing.sync="showingAuth" />

        <!-- Project list popup -->
        <project-list v-if="hasProjectList" @new="newProjectDialog = true" ref="projectList" />

        <!-- Overlay for disabling the UI -->
        <div class="disable-overlay" v-show="loading"></div>

        <!-- Loading overlay -->
        <v-overlay
            :value="!hasLoaded"
            :z-index="1000"
            opacity="1"
            color="white"
        >
            <v-progress-circular
                indeterminate
                size="32"
                :color="mlqDark"
            />
        </v-overlay>

        <!-- About / legal dialog -->
        <v-dialog
            v-model="showAbout"
            scrollable
            max-width="700"
        >
            <v-card>
                <v-card-title class="display-1">About MultiLinQ</v-card-title>

                <v-card-text>

                    MultiLinQ is a tool for linking system models to MDO toolchains.
                    <br /><br />

                    <h2>Contact</h2>

                    MultiLinQ is developed at the <b>DLR (German Aerospace Center)</b>,
                    Institute of System Architectures in Aeronautics, MDO group, in Hamburg, Germany.
                    Contact us at
                    <a href="mailto:luca.boggero@dlr.de">luca.boggero@dlr.de</a>
                    and/or
                    <a href="mailto:jasper.bussemaker@dlr.de">jasper.bussemaker@dlr.de</a>
                    for any questions or suggestions.

                    <br /><br />
                    You are running MultiLinQ v{{ version }}.

                    <!-- Legal text -->
                    <div v-if="hasLegalText">
                        <br />
                        <div class="display-1 black--text">Legal - impressum</div>

                        <div v-html="legalTextHtml"></div>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="primary" @click="showAbout = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
    import Vue from "vue";
    import includes from 'lodash/includes';
    import MarkdownIt from 'markdown-it';
    import mila from 'markdown-it-link-attributes';

    import {mdiPen, mdiContentSaveOutline, mdiFolderOpenOutline, mdiContentSaveEditOutline,
        mdiFileOutline, mdiHelpCircleOutline, mdiUndo, mdiRedo, mdiMatrix, mdiCog, mdiCalculator,
        mdiInformationOutline, mdiLogout, mdiCheckCircleOutline} from '@mdi/js';
    import {api, dispatcher, mlqDark, mlqLight} from "./main";
    import {saveFileContentCallback, openFile} from "./files";
    import {store, updatedProject} from "./store";

    import auth from './comp/auth.vue';
    import projectList from './comp/project-list.vue';
    import matrix from './comp/matrix.vue';
    import compqoi from './comp/comp_qoi.vue';
    import tools from './comp/tools.vue';
    import verify from './comp/verify.vue';

    export default Vue.extend({
        name: "app",
        components: {
            auth,
            projectList,
            matrix,
            compqoi,
            tools,
            verify,
        },
        data: () => ({
            mlqDark, mlqLight,
            minWidth: 800,

            mdiPen, mdiContentSaveOutline, mdiFolderOpenOutline, mdiContentSaveEditOutline,
            mdiFileOutline, mdiHelpCircleOutline, mdiUndo, mdiRedo, mdiMatrix, mdiCog,
            mdiInformationOutline, mdiLogout, mdiCalculator, mdiCheckCircleOutline,

            snackbar: false,
            snackbarMsg: null,
            snackbarCloseTimeout: 2000,
            snackbarColor: null,
            newProjectDialog: false,
            logoutDialog: false,

            loading: false,
            editingTitle: false,
            projectTitle: 'New Project',

            page: 'comp',
            pageColor: 'amber lighten-4',
            pageSelColor: mlqDark,

            showingAuth: false,
            showAbout: false,
        }),
        watch: {
            editingTitle(editingTitle) {
                if (editingTitle) {
                    this.projectTitle = this.project?.name;
                } else {
                    this._setProjectTitle(this.projectTitle);
                }
            },
        },
        computed: {
            project: () => store.state.localProject,
            unsaved: () => !store.state.backendState.persists,
            hasUndo: () => store.state.backendState.hasUndo,
            hasRedo: () => store.state.backendState.hasRedo,

            hasLoaded: () => store.state.hasLoaded,
            authUsername: () => store.getters.authUsername,
            selectedScope: () => store.state.selectedScope,

            hasLegalText: () => !!store.state.settings.legal_text_markdown,
            legalTextHtml() {
                if (!this.hasLegalText) return '';

                const md = new MarkdownIt();
                md.use(mila, {
                    attrs: {
                        target: '_blank',
                    },
                });
                return md.render(store.state.settings.legal_text_markdown);
            },

            settings: () => store.state.settings,
            isAuth: () => store.state.isAuth,
            hasAuth() {
                return this.settings.has_auth;
            },
            version() {
                return this.settings.version;
            },
            hasNewProject() {
                return this.settings.new_project;
            },
            hasLoadProject() {
                return this.settings.load_project;
            },
            hasSaveProject() {
                return this.settings.save_project;
            },
            hasSaveProjectAs() {
                return this.settings.save_project_as;
            },
            hasProjectList() {
                return this.settings.has_project_list;
            },
            hasBackendUI() {
                return this.settings.has_backend_ui;
            },
            logoFiles() {
                return this.settings.logo_files;
            },

            renderProjectTitle() {
                let title = ((this.project) ? this.project.name: '')+((this.unsaved) ? '*': '');
                if (this.settings.display_selected_scope && this.selectedScope) title = this.selectedScope+': '+title;
                return title;
            },
            documentTitle() {
                let title = 'MultiLinQ';
                if (this.version) title += ' v'+this.version;
                if (this.unsaved) title += '*';
                return title;
            },
            large() {
                return this.$vuetify.breakpoint.mdAndUp;
            },
        },
        methods: {
            _startEditTitle() {
                if (!this.editingTitle) {
                    this.editingTitle = true;
                    this.$nextTick(() => this.$refs.titleField.focus());
                }
            },
            _titleKeyUp(e) {
                if (includes([13, 27], e.keyCode)) { // Enter || Esc
                    this.editingTitle = false;
                }
            },

            _setProjectTitle(title) {
                store.dispatch.setProjectName(title);
            },
            _startProject() {
                store.dispatch.updateBackendState();
            },

            _fileOpsCallback(project, response) {
                updatedProject(project, response);
                dispatcher.fileOps();
            },
            _newProject() {
                this.newProjectDialog = false;
                if (!this.hasNewProject) return;
                api.newProject(this._fileOpsCallback);
            },
            _loadProject() {
                if (!this.hasLoadProject) return;

                if (this.hasBackendUI) {
                    api.loadProject(this._fileOpsCallback);
                } else {
                    openFile((data) => {
                        api.uploadProject(data, this._fileOpsCallback);
                    });
                }
            },
            _openProjectList() {
                if (!this.hasProjectList) return;
                this.$refs.projectList.show();
            },
            _saveProject() {
                if (!this.hasSaveProject) {
                    if (this.hasSaveProjectAs) this._saveProjectAs();
                    return;
                }

                api.saveProject(updatedProject);
            },
            _saveProjectAs() {
                if (!this.hasSaveProjectAs) return;

                if (this.hasBackendUI || this.hasProjectList) {
                    api.saveProjectAs(updatedProject);
                } else {
                    api.downloadProject(null, saveFileContentCallback);
                }
            },
            _showQuickReference() {
                window.open('/static/MultiLinQ_QRM.pdf', '_blank');
            },

            _isLoading(loading) {
                this.loading = loading;
            },
            _handleKeyDown(e) {
                this._handleKeyPresses(e, true);
            },
            _handleKeyUp(e) {
                this._handleKeyPresses(e, false);
            },
            _handleKeyPresses(e, doAction) {
                if (this.loading) doAction = false;

                // Custom key press handlers
                if (dispatcher.handleKeyPress(e, doAction)) return;

                // https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
                // https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent/key/Key_Values
                switch (e.key) {

                    case "Enter":
                        if (doAction && this.newProjectDialog) {
                            this._newProject();
                            e.preventDefault();
                        }
                        break;

                    case "F1":
                        if (doAction) this._showQuickReference();
                        e.preventDefault();
                        break;

                    case "s":
                        if (e.ctrlKey) {
                            if (doAction) this._saveProject();
                            e.preventDefault();
                        }
                        break;

                    case "S":
                        if (e.ctrlKey) {
                            if (doAction) this._saveProjectAs();
                            e.preventDefault();
                        }
                        break;

                    case "N": // N
                        if (e.ctrlKey) {
                            if (doAction) this.newProjectDialog = true;
                            e.preventDefault();
                        }
                        break;

                    case "o": // O
                        if (e.ctrlKey) {
                            if (doAction) {
                                if (this.hasProjectList) {
                                    this._openProjectList();
                                } else {
                                    this._loadProject();
                                }
                            }
                            e.preventDefault();
                        }
                        break;

                    case "z": // Z
                        if (e.ctrlKey) {
                            if (doAction) this._undo();
                            e.preventDefault();
                        }
                        break;

                    case "y": // Y
                        if (e.ctrlKey) {
                            if (doAction) this._redo();
                            e.preventDefault();
                        }
                        break;

                }
            },

            _undoRedoCallback(project, response) {
                updatedProject(project, response);
                dispatcher.undoRedo();
            },
            _undo() {
                if (!this.hasUndo) return;
                api.undo(this._undoRedoCallback);
            },
            _redo() {
                if (!this.hasRedo) return;
                api.redo(this._undoRedoCallback);
            },

            _success(msg) {
                this._openSnackbar(msg, 'success');
            },
            _error(msg) {
                this._openSnackbar(msg, 'error');
            },
            _openSnackbar(msg, color) {
                this.snackbarMsg = msg;
                this.snackbarColor = color || 'info';

                if (this.snackbar) {
                    this.snackbar = false;
                    this.$nextTick(() => this.snackbar = true);
                } else {
                    this.snackbar = true;
                }
            },

            _logout() {
                this.logoutDialog = false;
                api.logout(() => {
                    this._startProject();
                });
            },
            _displayAbout() {
                this.showAbout = true;
            },
        },
        created() {
            dispatcher.onSuccess(this._success);
            dispatcher.onError(this._error);
            dispatcher.onIsLoading(this._isLoading);

            dispatcher.onDisplayAbout(this._displayAbout);

            window.addEventListener('keydown', this._handleKeyDown);
            window.addEventListener('keyup', this._handleKeyUp);
        },
    });
</script>

<style scoped>
    .main-toolbar {
        z-index: 100 !important;
    }
    .v-toolbar.content-toolbar {
        z-index: 50;
    }

    #bg-logos {
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;
        padding: 15px;
        opacity: 0.3;
        z-index: 10000;
    }
    #bg-logos img {
        max-height: 50px;
        margin-right: 20px;
    }
    #bg-logos img:last-child {
        margin-right: 0;
    }

    .toolbar-title {
        min-width: 500px;
    }
    .title-edit-button {
        visibility: hidden;
    }
    .toolbar-title:hover .title-edit-button {
        visibility: visible;
    }

    .toolbar-center {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
    }
    .toolbar-center > div {
        position: relative;
        left: -50%;
        height: 100%;
        width: 200%;
    }
    .toolbar-center > div > .v-btn {
        border-radius: 0;
        height: 100% !important;
        max-height: none;
    }

    .content-main-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .disable-overlay {
        position: fixed;
        z-index: 99999999;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0);
        cursor: wait;
    }
</style>