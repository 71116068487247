import { Manager, Socket } from 'socket.io-client';
import {dispatcher} from "./main";
import * as m from "./model/model";
import b64ToBlob from 'b64-to-blob';
import pako from 'pako';
import Cookies from 'js-cookie';
import {parse, stringify} from './json-parser';
import * as jsonpatch from 'fast-json-patch';

import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import {ArchitectureInstanceData} from "./model/model";
import {SettingsObject} from "@/store";

export interface LoginData {
    username?: string,
    password?: string,
}

interface ReturnData {
    success: boolean,
    msg?: string,
    data?: any,
    comp?: boolean,
    auth: boolean|string,
    scope: string|null,
}

export interface FileContent {
    filename: string,
    data: string, // base64 encoded
    mimeType: string,
}
type FileContentCallback = (fileContent: FileContent) => void;

export interface ProjectResponse {
    json: string,
    diff: boolean,
    state: BackendState,
}
export interface BackendState {
    persists: boolean,
    hasUndo: boolean,
    hasRedo: boolean,
    nextId: m.idType,
}
export type ProjectResponseCallback = (project: m.Project, response: BackendState) => void;
export type ProjectListResponseCallback = (projects: {ref: string, name: string, created: string, updated: string}[]) => void;

export type EmptyCallback = () => void;
export type ErrorCallback = (d: ReturnData) => void;
export type ScopeListCallback = (scopes: [string, string][]) => void;

export interface ArchMappingSessionState {
    archDataFile: string|null,
    initDataFile: string|null,
    outputDataFile: string|null,

    // initData: m.DataInstance[]|null,
    // outputData: m.DataInstance[]|null,

    architecture: [string, string][],
    archKey: string|null,
    archInputData: ArchitectureInstanceData|null,
    archOutputData: ArchitectureInstanceData|null,

    // mappedInputData: m.DataInstance[]|null,
    // mappedOutputData: m.DataInstance[]|null,
}
export type ArchMappingStateCallback = (state: ArchMappingSessionState) => void;

// Dispatcher is not yet instantiated
let debouncedLoading = debounce((_) => {}, 0);

export default class Api {
    socket: Socket;
    routeNamespace?: string;
    oldSession?: string;
    loadingEvents: [string, Date][];
    loadingTimeout: number = 10;
    currentProject: m.Project|null;

    constructor(settings: SettingsObject) {
        this.loadingEvents = [];
        this.currentProject = null;
        this.routeNamespace = settings.namespace;

        this.oldSession = Cookies.get('io');
        const namespace = (this.routeNamespace) ? '/'+this.routeNamespace: '';
        const manager = new Manager(window.location.protocol+'//'+document.domain+':'+location.port, {
            path: namespace+'/socket.io/',
        });
        this.socket = manager.socket('/');

        // Migrate backend session
        dispatcher.isLoading(true);
        this.socket.on('connect', () => {
            const sessionId = this.sessionId();
            if (this.oldSession && sessionId !== this.oldSession) {
                this.migrateSession(this.oldSession);
            } else {
                dispatcher.updatedBackendState();
            }
            Cookies.set('io', sessionId, { expires: 1, sameSite: 'strict' });
        });

        // Only at this point the dispatcher has been instantiated
        debouncedLoading = debounce(dispatcher.isLoading, 500);

        this.startKeepalive(2000);
        setInterval(() => this.checkFinishedLoading(), 1000);
    }

    migrateSession(oldSessionId: string) {
        this.call('migrate-session', oldSessionId, () => {
            this.oldSession = this.sessionId();
            dispatcher.isLoading(true);
            dispatcher.updatedBackendState();
            dispatcher.fileOps();
        });
    };

    sessionId() {
        let sid = this.socket.id;
        if (sid[0] === '/') sid = sid.split('#')[1];
        return sid;
    }

    login(data: LoginData, callback?: EmptyCallback, errorCallback?: ErrorCallback) {
        this.call('login', data, callback, errorCallback);
    }

    logout(callback?: EmptyCallback) {
        this.call('logout', null, callback);
    }

    listScopes(callback: ScopeListCallback) {
        this.call('list-scopes', null, callback);
    }

    selectScope(scopeKey: string, callback?: EmptyCallback) {
        this.call('select-scope', scopeKey, callback);
    }

    /**
     * Get the current active project.
     */
    getProject(callback?: ProjectResponseCallback) {
        this.call('get-project', null, this.projectResponseCallback(callback));
    }

    /**
     * Update the current active project.
     */
    setProject(project: m.Project, callback?: ProjectResponseCallback) {
        this.call('set-project', this.serializeProject(project), this.projectResponseCallback(callback));
    }

    serializeProject(project: m.Project): [boolean, string] {
        let diff = false;
        const projectObj = project;
        let data = stringify(projectObj);

        if (this.currentProject !== null) {
            const patch = stringify(jsonpatch.compare(this.currentProject, projectObj));
            if (patch.length < data.length) {
                // console.log('DO DIFF', patch.length, '<', data.length, patch);
                diff = true;
                data = patch;
            }
        }

        return [diff, data];
    }

    projectResponseCallback(callback?: ProjectResponseCallback) {
        return (response: ProjectResponse) => {
            if (!callback) return;

            if (response.diff) {
                if (this.currentProject === null) throw new Error('Response is diff but no current project set!');

                const patch = parse(response.json) as jsonpatch.Operation[];
                // console.log('APPLYING DIFF', patch.length);
                const projectJson: m.Project = JSON.parse(JSON.stringify(this.currentProject));
                jsonpatch.applyPatch(projectJson, patch);

                callback(projectJson, response.state);
            } else {
                callback(parse(response.json), response.state);
            }
        }
    }

    /**
     * Initialize a new project.
     */
    newProject(callback?: ProjectResponseCallback) {
        this.call('new-project', null, this.projectResponseCallback(callback));
    }

    /**
     * List available projects.
     */
    listProjects(callback?: ProjectListResponseCallback) {
        this.call('list-projects', null, callback);
    }

    /**
     * Add a project (from file) to the project list.
     */
    importProject(data: string, callback?: ProjectListResponseCallback) {
        this.call('import-project', data, callback);
    }

    /**
     * Select a project from available projects.
     */
    selectProject(ref: string, callback?: ProjectResponseCallback) {
        this.call('select-project', ref, this.projectResponseCallback(callback));
    }

    /**
     * Delete a project from available projects.
     */
    deleteProject(ref: string, callback?: ProjectResponseCallback) {
        this.call('delete-project', ref, this.projectResponseCallback(callback));
    }

    /**
     * Load a project from disk (backend asks for file location).
     */
    loadProject(callback?: ProjectResponseCallback) {
        this.call('load-project', null, this.projectResponseCallback(callback));
    }

    /**
     * Upload data from a project file to be used as the current project.
     */
    uploadProject(data: string, callback?: ProjectResponseCallback) {
        this.call('upload-project', data, this.projectResponseCallback(callback));
    }

    /**
     * Save the project to the currently used location.
     */
    saveProject(callback?: ProjectResponseCallback) {
        this.call('save-project', null, this.projectResponseCallback(callback));
    }

    /**
     * Save the project to a new location (backend asks for file location).
     */
    saveProjectAs(callback?: ProjectResponseCallback) {
        this.call('save-project-as', null, this.projectResponseCallback(callback));
    }

    /**
     * Save the project and download the project file content.
     */
    downloadProject(ref?: string|null, callback?: FileContentCallback) {
        this.call('download-project', (ref) ? ref: null, callback);
    }

    /**
     * Upload a file as tool I/O.
     */
    uploadIO(toolId: m.idType, isInput: boolean, data: string, callback?: ProjectResponseCallback) {
        this.call('upload-io', [toolId, isInput, data], this.projectResponseCallback(callback));
    }

    /**
     * Ask backend to select a file as tool I/O.
     */
    selectIO(toolId: m.idType, isInput: boolean, callback?: ProjectResponseCallback) {
        this.call('select-io', [toolId, isInput], this.projectResponseCallback(callback));
    }

    /**
     * Search some data root with a term, possibly an xpath. Set toolId=null to search the project's merged data.
     */
    searchData(toolId: m.idType|null, isInput: boolean, term: string, callback: (ids: m.idType[]) => void) {
        this.call('search-data', [toolId, isInput, term], callback);
    }

    /**
     * Import components and QOIs given some data (from file) and an import type key (see api.py:_comp_qoi_importers).
     */
    importCompQoi(key: string, data: string, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('import-comp-qoi', [key, data], this.projectResponseCallback(callback), errorCallback);
    }

    /**
     * Import tools given some data (from file) and an import type key (see api.py:_tool_importers).
     */
    importTools(key: string, data: string, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('import-tools', [key, data], this.projectResponseCallback(callback), errorCallback);
    }

    /**
     * Get a list of available component/QOI sets to import.
     */
    getCompQoiSets(callback?: (sets: string[]) => void) {
        this.call('get-comp-qoi-sets', null, callback);
    }

    /**
     * Import a component/QOI set.
     */
    loadCompQoiSet(idx: number, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('load-comp-qoi-set', idx, this.projectResponseCallback(callback), errorCallback);
    }

    /**
     * Get a list of available tool sets to import.
     */
    getToolSets(callback?: (sets: string[]) => void) {
        this.call('get-tool-sets', null, callback);
    }

    /**
     * Import a tool set.
     */
    loadToolSet(idx: number, callback?: ProjectResponseCallback, errorCallback?: ErrorCallback) {
        this.call('load-tool-set', idx, this.projectResponseCallback(callback), errorCallback);
    }

    /**
     * Render a SVG data into some other format.
     */
    renderSvg(svgData: string, targetFormat: string, callback?: FileContentCallback) {
        this.call('render-svg', [svgData, targetFormat], callback);
    }

    /**
     * Undo the previous action.
     */
    undo(callback?: ProjectResponseCallback) {
        this.call('undo', null, this.projectResponseCallback(callback));
    }

    /**
     * Redo the next action.
     */
    redo(callback?: ProjectResponseCallback) {
        this.call('redo', null, this.projectResponseCallback(callback));
    }

    /**
     * Get current manual architecture mapping state.
     */
    archMappingGetState(callback?: ArchMappingStateCallback) {
        this.call('am-get-state', null, callback);
    }

    /**
     * Reset the manual architecture mapping session.
     */
    archMappingReset(callback?: ArchMappingStateCallback) {
        this.call('am-reset', null, callback);
    }

    /**
     * Upload the initial data schema file.
     */
    archMappingUploadInit(type: string, data: string, filename: string, callback?: ArchMappingStateCallback) {
        this.call('am-upload-init', [type, data, filename], callback);
    }

    /**
     * Upload the output data schema file (should be same type as initial file).
     */
    archMappingUploadOutput(type: string, data: string, filename: string, callback?: ArchMappingStateCallback) {
        this.call('am-upload-output', [type, data, filename], callback);
    }

    /**
     * Upload the architecture data file.
     */
    archMappingUploadArch(type: string, data: string, filename: string, callback?: ArchMappingStateCallback) {
        this.call('am-upload-arch', [type, data, filename], callback);
    }

    /**
     * Select an architecture.
     */
    archMappingSelectArch(key: string, callback?: ArchMappingStateCallback) {
        this.call('am-select-arch', key, callback);
    }

    /**
     * Perform input and output mapping.
     */
    archMappingDoMapping(callback?: ArchMappingStateCallback) {
        this.call('am-do-mapping', null, callback);
    }

    /**
     * Download the mapped input file as it would be sent to the evaluation environment.
     */
    archMappingDownloadMappedInput(callback?: FileContentCallback) {
        this.call('am-dl-mapped-input', null, callback);
    }

    /**
     * Helper function for calling an API endpoint. See api.py for available endpoints.
     */
    call(target: string, args?: any, callback?: Function, errorCallback?: ErrorCallback,
         suppressErrorDisplay: boolean = false) {

        if (isUndefined(args)) args = null;
        this.startLoading(target);

        this.socket.emit(target, args, (d: ReturnData) => {
            // Check for formatting
            if (typeof d === 'undefined' || !d.hasOwnProperty('success')) {
                console.log('Malformed response (forgot to use success() or error() in api.py?)', target, args, d);
                this.error('Malformed response from '+target);
                this.finishLoading(target);
                return;
            }

            dispatcher.setAuthStatus(d.auth, d.scope);

            // Check success
            if (d.success) {
                if (d.msg) this.success(d.msg);

                if (callback) {
                    if (d.comp) {
                        this.decompress(callback, d.data);
                    } else {
                        callback(d.data);
                    }
                }

            } else {
                console.log('Error', target, d.msg);

                if (d.msg && !suppressErrorDisplay) this.error(d.msg);

                if (errorCallback) errorCallback(d);
            }
            this.finishLoading(target);
        });
    }

    success(msg: string) { dispatcher.success(msg); }
    error(msg: string) { dispatcher.error(msg); }

    decompress(callback: Function, data: string) {
        const blob = b64ToBlob(data, 'application/octet-stream');
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const arrayBuffer = event.target?.result as Uint8Array;
            const jsonString = pako.inflate(arrayBuffer, { to: 'string' });
            // console.log(`Inflated ${new Blob([data]).size} to ${new Blob([jsonString]).size} bytes`);
            const jsonData = parse(jsonString);
            callback(jsonData);
        };
        fileReader.readAsArrayBuffer(blob);
    }

    callKeepalive() {
        this.call('keepalive');
    }
    startKeepalive(timeout: number) {
        const that = this;
        setInterval(() => that.callKeepalive(), timeout);
    }

    startLoading(event: string) {
        this.loadingEvents.push([event, new Date()]);
        debouncedLoading(true);
    }
    finishLoading(event: string) {
        let iFound: number = -1;
        for (let i = 0; i < this.loadingEvents.length; i++) {
            if (this.loadingEvents[i][0] == event) iFound = i;
        }
        if (iFound !== null) this.loadingEvents.splice(iFound, 1);

        this.checkFinishedLoading();
    }
    checkFinishedLoading() {
        const timeout = this.loadingTimeout;
        // https://github.com/Microsoft/TypeScript/issues/5710#issuecomment-157886246
        this.loadingEvents = filter(this.loadingEvents, (evt) => (+(new Date()) - +evt[1])/1000 < timeout);

        if (this.loadingEvents.length == 0) {
            debouncedLoading.cancel();
            dispatcher.isLoading(false);
        }
    }
}
