import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';


const parse = (text: string, reviver?: any): any => {

    function wrappedReviver(key: any, value: any): any {
        if (value == '__NaN__') return NaN;
        if (!reviver) return value;
        // @ts-ignore
        return reviver.call(this, key, value);
    }

    return JSON.parse(text.replace(/"value": NaN/g, '"value": "__NaN__"'), wrappedReviver);
};

const stringify = (value: any, replacer?: any, space?: any) => {

    function wrappedReplacer(key: any, value: any) {
        // Remove vue props
        if (key === 'dep' || key === 'vmCount') return undefined;

        if (Number.isNaN(value)) return '__NaN__';
        if (!replacer) return value;
        // @ts-ignore
        return replacer.call(this, key, value);
    }

    if (value === undefined) return '';
    return JSON.stringify(value, wrappedReplacer, space).replace(/"__NaN__"/g, 'NaN');
};

export {parse, stringify};
