import Vue from 'vue';
import Vuex from 'vuex';
import * as m from './model/model';
import {api} from "./main";
import {BackendState, ProjectResponseCallback, ArchMappingSessionState, ArchMappingStateCallback} from "./api";

import assign from 'lodash/assign';

import {createDirectStore} from "direct-vuex";
Vue.use(Vuex);

export interface SettingsObject {
    version: string,
    namespace?: string,

    new_project: boolean,
    load_project: boolean,
    save_project: boolean,
    save_project_as: boolean,
    delete_project: boolean,
    import_project: boolean,
    has_project_list: boolean,
    has_backend_ui: boolean,

    edit_refs: boolean,
    edit_comp_qoi: boolean,
    import_comp_qoi: boolean,
    edit_tools: boolean,
    import_tools: boolean,

    db_provides_comp_qoi: boolean,
    db_provides_tools: boolean,

    has_auth: boolean,
    login_fields?: string[],
    scope_name?: string,
    remote_name?: string,
    display_selected_scope: boolean,

    legal_text_markdown?: string,
    logo_files: string[],
}

export interface RootState {
    settings: SettingsObject,
    project: null|m.Project,
    localProject: null|m.Project,
    backendState: BackendState,
    localNextId: m.idType,
    editable: boolean,

    archMappingState: ArchMappingSessionState|null,

    isAuth: boolean|string,
    selectedScope: string|null,
    hasLoaded: boolean,
}

const {store, rootActionContext, moduleActionContext} = createDirectStore({
    strict: true,
    state: {
        settings: {},
        project: null,
        localProject: null,
        backendState: {
            persists: true,
            hasUndo: false,
            hasRedo: false,
            nextId: 1,
        },
        localNextId: 1,
        editable: true,

        isAuth: false,
        selectedScope: '',
        hasLoaded: false,
    } as RootState,

    getters: {
        hasSelectedScope(state): boolean {
            return state.selectedScope !== null;
        },
        authUsername(state): string {
            return (state.isAuth === true) ? '': (state.isAuth || '');
        },
    },

    mutations: {
        commitProject(state: RootState, {project, response}: {project: m.Project, response: BackendState}) {
            state.project = project;
            state.localProject = project;
            state.backendState = response;
            state.localNextId = response.nextId;

            api.currentProject = project;

            state.hasLoaded = true;
        },
        commitBackendState(state: RootState, response: BackendState) {
            state.backendState = response;
            state.localNextId = response.nextId;
        },
        commitOverrideNextId(state: RootState, nextId: m.idType) {
            state.localNextId = nextId;
        },
        incrementNextId(state: RootState) {
            state.localNextId++;
        },
        commitSettings(state: RootState, settings: SettingsObject) {
            state.settings = settings;
        },
        commitLocalProject(state: RootState, project: m.Project) {
            state.localProject = project;
            state.backendState.persists = false;
        },
        commitAuthState(state: RootState, {isAuth, selectedScope}: {isAuth: boolean|string, selectedScope: string|null}) {
            if (isAuth !== state.isAuth || selectedScope !== state.selectedScope) {
                state.isAuth = isAuth;
                state.selectedScope = selectedScope;
            }
        },
        commitArchMappingState(state: RootState, archMappingState: ArchMappingSessionState) {
            Vue.set(state, 'archMappingState', archMappingState);
        },
    },

    actions: {
        updateBackendState(context) {
            const { commit } = rootActionContext(context);
            api.getProject((project, response) => {
                commit.commitProject({project, response});
            });
        },
        setProject(context, {project, callback}: {project: m.Project, callback?: (project: m.Project) => void}) {
            const { commit } = rootActionContext(context);
            api.setProject(project, (project, response) => {
                commit.commitProject({project, response});
                if (callback) callback(project);
            });
            commit.commitLocalProject(project);
        },

        setProjectName(context, name: string) {
            const { state, dispatch } = rootActionContext(context);
            dispatch.setProject({project: assign({}, state.project, {name})});
        },
    },
});

export default store;
export {
    store,
    rootActionContext,
    moduleActionContext,
}

export const moduleGetterContext = <T>(args: [any, any, any, any]):
    { state: T, rootState: RootState } => ({ state: args[0], rootState: args[2] });

export const updatedProject: ProjectResponseCallback = (project, response) => {
    store.commit.commitProject({project, response});
};
export const editProject = (edit: (project: m.Project) => void, project?: m.Project, clone: boolean = true,
                            set: boolean = true, callback?: (project: m.Project) => void) => {
    if (project === undefined) {
        if (store.state.localProject === null) throw new Error('No project set');
        project = store.state.localProject;
    }
    if (clone) project = JSON.parse(JSON.stringify(project)) as m.Project;
    edit(project);
    if (set) store.dispatch.setProject({project, callback});
    return project;
};
export const getNextId = (): m.idType => {
    const nextId = store.state.localNextId;
    store.commit.incrementNextId();
    return nextId;
};
export const updatedArchMappingState: ArchMappingStateCallback = (state) => {
    store.commit.commitArchMappingState(state);
};
