<template>
    <v-form v-model="formValid" ref="form" @submit.prevent="">
        <!-- Operation type select -->
        <div v-if="hasOpsTypeSelect">
            <v-subheader>Data schema operation type</v-subheader>
            <div>
                <v-select
                    v-model="localValue.type"
                    :items="opsTypeItems"
                    :disabled="!editable"
                    label="DSO type"
                    @input="_input"
                />
            </div>
        </div>

        <!-- Multiplicity behavior select -->
        <div v-if="hasNoneActionSelect || hasMultiActionSelect">
            <v-subheader>{{ (isQoi) ? 'QOI': 'Component' }} multiplicity behavior</v-subheader>
            <v-row>
                <v-col cols="12" md="6" v-if="hasNoneActionSelect"><!-- None (0) action -->
                    <v-select
                        v-model="localValue.noneAction"
                        :items="noneActionItems"
                        :disabled="!editable"
                        label="Zero (0) element instances action"
                        @input="_input"
                    />
                </v-col>
                <v-col cols="12" md="6" v-if="hasMultiActionSelect"><!-- Multi (>1) action -->
                    <v-select
                        v-model="localValue.multiAction"
                        :items="multiActionItems"
                        :disabled="!editable"
                        label="Multiple (>1) element instances action"
                        @input="_input"
                    />
                </v-col>
            </v-row>
        </div>

        <!-- XPath input -->
        <v-subheader>Manually Enter XPath</v-subheader>
        <div>
            <v-text-field
                v-model="localValue.dataXpath"
                :disabled="!editable"
                label="XPath"
                clearable
                @blur="_xpathInput"
                @keyup.enter.stop="_xpathInput"
                @click:clear="_xpathClearInput"
                ref="xpathInput"
            />
        </div>

        <v-subheader>
            <v-btn @click="renderTree = !renderTree" elevation="0" color="write"
            ><v-icon left>{{ (renderTree) ? mdiMenuDown: mdiMenuRight }}</v-icon> Select From Tree</v-btn>
        </v-subheader>
        <div v-if="renderTree">
            <v-divider class="mb-4" />

            <!-- Input tree -->
            <div v-if="!isOutput">
                <div class="text-caption">Select data point to write to:</div>
                <data-tree
                    v-if="firstUpdatedValue"
                    :data="inputData"
                    no-data-text="No input data defined"
                    :selectable="editable"
                    :searchable="true"
                    v-model="localValue.dataId"
                    @input="_dataTreeInput"
                    key="input"
                    ref="inputDataTree"
                />
            </div>

            <!-- Output tree -->
            <div v-else>
                <div class="text-caption">Select data point to read from:</div>
                <data-tree
                    v-if="firstUpdatedValue"
                    :data="outputData"
                    no-data-text="No output data defined"
                    :selectable="editable"
                    :searchable="true" :search-is-input="false"
                    v-model="localValue.dataId"
                    @input="_dataTreeInput"
                    key="output"
                    ref="outputDataTree"
                />
            </div>
        </div>
    </v-form>
</template>

<script>
    import Vue from "vue";
    import {store} from '@/store';
    import * as m from '@/model/model';
    import {getDefaultOps} from "@/model/ops-utils";

    import {mdiMenuRight, mdiMenuDown} from '@mdi/js';

    import map from 'lodash/map';
    import includes from 'lodash/includes';
    import cloneDeep from 'lodash/cloneDeep';

    import dataTree from './data-tree.vue';

    export default Vue.extend({
        name: "ops",
        components: {
            dataTree,
        },
        props: {
            value: {required: true},
            isQoi: {required: true},
            isOutput: {required: true},
            editable: {default: true},
        },
        data: () => ({
            mdiMenuRight, mdiMenuDown,

            localValue: {},
            firstUpdatedValue: false,
            renderTree: false,

            formValid: true,
            OpsTypes: m.OperationType,

            rules: {
                required: (value) => !!value || 'Required',
            },
        }),
        watch: {
            value: {
                handler(value) {
                    this._updatedValue(value);
                },
                deep: true,
            },
            renderTree(renderTree) {
                if (renderTree) this._expandData();
            },
        },
        computed: {
            project: () => store.state.localProject,
            inputData() {
                return this.project.data.input;
            },
            outputData() {
                return this.project.data.output;
            },
            opsType() {
                return this.localValue.type;
            },
            opsTypeItems() {
                if (this.isQoi) return [];
                return [
                    { value: m.OperationType.CREATE_NODE, text: 'Create/copy node' },
                    { value: m.OperationType.WRITE_NR_INST, text: 'Write nr of component instances' },
                ];
            },
            hasOpsTypeSelect() {
                return this.opsTypeItems.length > 0;
            },
            possibleOpsTypes() {
                return map(this.opsTypeItems, (item) => item.value);
            },
            noneActionItems() {
                const opsType = this.opsType;
                if (this.isQoi) {
                    if (!this.isOutput) return [
                        { value: m.NoneActionType.DO_NOTHING, text: 'Do nothing (skip)' },
                        { value: m.NoneActionType.EMPTY_NODE, text: 'Clear node value' },
                        { value: m.NoneActionType.REMOVE_NODE, text: 'Remove node' },
                    ];
                    return [];
                }

                return (opsType === m.OperationType.CREATE_NODE) ? [
                    { value: m.NoneActionType.DO_NOTHING, text: 'Do nothing (skip)' },
                    { value: m.NoneActionType.REMOVE_NODE, text: 'Remove node' },
                ]: [];
            },
            hasNoneActionSelect() {
                return this.noneActionItems.length > 0;
            },
            possibleNoneActions() {
                return map(this.noneActionItems, (item) => item.value);
            },
            multiActionItems() {
                if (this.isQoi) {
                    if (this.isOutput) {
                        return [
                            { value: m.MultiActionType.VALUE_LIST, text: 'Read from list of values (;-separated)' },
                            { value: m.MultiActionType.NODE_COPIES, text: 'Read from node copies' },
                        ];
                    }
                    return [
                        { value: m.MultiActionType.VALUE_LIST, text: 'Write as a list of values (;-separated)' },
                        { value: m.MultiActionType.NODE_COPIES, text: 'Create copies of selected data node' },
                    ];
                }
                return [];
            },
            hasMultiActionSelect() {
                return this.multiActionItems.length > 0;
            },
            possibleMultiActions() {
                return map(this.multiActionItems, (item) => item.value);
            },
        },
        methods: {
            _updatedValue(value) {
                if (this.$refs.form) this.$refs.form.resetValidation();

                const defaultOps = getDefaultOps(this.isQoi, this.isOutput);
                if (!value) {
                    this.localValue = defaultOps;
                } else {
                    this.localValue = cloneDeep(value);
                    if (!includes(this.possibleOpsTypes, this.localValue.type)) {
                        this.localValue.type = defaultOps.type;
                    }
                    if (!includes(this.possibleNoneActions, this.localValue.noneAction)) {
                        this.localValue.noneAction = defaultOps.noneAction;
                    }
                    if (!includes(this.possibleMultiActions, this.localValue.multiAction)) {
                        this.localValue.multiAction = defaultOps.multiAction;
                    }
                }

                this._expandData();
            },
            _expandData() {
                if (this.localValue.dataId) {
                    this.$nextTick(() => {
                        const dataTree = (this.isOutput) ? this.$refs.outputDataTree: this.$refs.inputDataTree;
                        if (dataTree) dataTree.expandValue();
                    });
                }
            },
            _xpathClearInput() {
                this.$refs.xpathInput.blur();
            },
            _xpathInput() {
                this.localValue.dataId = null;
                if (this.localValue.dataXpath === '') this.localValue.dataXpath = null;
                this._input();
            },
            _dataTreeInput() {
                this.localValue.dataXpath = null;
                this._input(true);
            },
            _input(noValidate) {
                if (!this.editable) return;

                if (noValidate) {
                    this.$emit('input', this.localValue);
                    return;
                }

                this.$refs.form.validate();
                this.$nextTick(() => {
                    if (!this.$refs.form.validate()) return;
                    if (!this.formValid) return;

                    this.$emit('input', this.localValue);
                });
            },
        },
        mounted() {
            this.$nextTick(() => {
                this._updatedValue(this.value);
                this.firstUpdatedValue = true;
            });
        },
    });
</script>

<style scoped>

</style>